import React, { Component } from "react";
import { Table, Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import locale from "./localeTable";
// import Highlighter from "react-highlight-words";

class TableItem extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
  };
  getColumnSearchProps = (dataIndex, render) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    // render: (text) => {
    //   return this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ""}
    //     />
    //   ) : (
    //     text
    //   )},
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    let columns = this.props.columns.map((value) => {
      let newVal = { ...value };
      if (value.buscar) {
        newVal = { ...value, ...this.getColumnSearchProps(value.buscar) };
      }

      return newVal;
    });
    return (
      <Table
        locale={{...locale, ...this.props.locale}}
        columns={columns.map(valueColum => {
          let ellipsis = false
          if(valueColum.dataIndex !== 'accion') ellipsis = true
          // if(valueColum.ellipsis !== undefined) ellipsis = valueColum.ellipsis
          if(valueColum.dataIndex === 'subtotal' || valueColum.dataIndex === 'total'){
            return {...valueColum, ellipsis, align: 'right'}
          }else{
            return {...valueColum, ellipsis}
          }
        })}
        dataSource={this.props.dataSource}
        rowSelection={this.props.rowSelection}
        onRow={this.props.onRow}
        components={this.props.components}
        loading={this.props.loading}
        size={this.props.size}
        rowClassName={this.props.rowClassName}
        rowKey={this.props.rowKey ? this.props.rowKey : (value) => value._id}
        tableLayout='fixed'
        scroll={{ y: '60vh' }}
        pagination={{defaultPageSize: 100}}
      />
    );
  }
}

export default TableItem;

import { Form as FormInicial, message } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Form,
    FormItem,
    InputNumberItem,
    ModalItem,
    round10Decimals,
    ButtonItem,
} from "../../../../Components/Items";
import axios from "axios";

class ElegirMarbetes extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        marbetes: []
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            this.consultarMarbetesRestantes()
        }
    }

    cerrarElegirMarbetes() {
        const marbetes = this.formRef.current.getFieldValue('marbetes')
        this.props.cerrarElegirMarbetes(marbetes);
        this.formRef.current.resetFields()
    }

    async consultarMarbetesRestantes() {
        this.setState({ loading: true });
        await axios
            .post(
                "insumos/selectMarbetesDisponibles",
                { empresaId: this.props.empresaId, insumoId: this.props.insumoId, proyectoId: this.props.proyectoId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    const nuevosmarbetes = res.data.marbetesDisponibles.map(valueMarbete => {
                        const marbeteActual = this.props?.marbetes ? this.props?.marbetes?.find(valueTM => valueTM._id.toString() === valueMarbete._id.toString()) : undefined
                        return {
                            ...valueMarbete,
                            cantidadRequerida: marbeteActual?.cantidadRequerida || 0
                        }
                    })
                    this.setState({marbetes: nuevosmarbetes})
                    this.formRef.current.setFieldsValue({
                        marbetes: nuevosmarbetes
                    })
                } else if (res.data.success === 1) {
                    message.warning(res.data.message)
                } else {
                    message.error('Hubo un error y no se pudo consultar los marbetes disponibles')
                }
            });
        this.setState({ loading: false });
    }

    onFinish() {
        this.cerrarElegirMarbetes()
    }

    onFinishFailed(e) {
        e.errorFields.map((value, index) => {
            if (e.errorFields[index].name[1]) {
                message.error(
                    "Error: " +
                    e.errorFields[index].errors[0] +
                    " en la fila " +
                    (e.errorFields[index].name[1] + 1)
                );
            } else {
                message.error("Error: Falta un campo");
            }
            return null;
        });
    }

    // onFinishDesplejar(e) {
    //     if (e.inicio < e.final) {
    //         let arrayMarbetes = []
    //         for (let i = e.inicio; i <= e.final; i++) {
    //             arrayMarbetes.push({
    //                 numero: i,
    //                 cantidad: e.cantidad
    //             })
    //         }
    //         const marbetesActuales = this.formRef.current.getFieldValue('marbetes')
    //         if (marbetesActuales && marbetesActuales?.length > 0) {
    //             this.formRef.current.setFieldsValue({ marbetes: [...marbetesActuales, ...arrayMarbetes] })
    //         } else {
    //             this.formRef.current.setFieldsValue({ marbetes: arrayMarbetes })
    //         }
    //         this.formRefDesplejar.current.resetFields()
    //     } else {
    //         message.warning('El inicio tiene que ser menor que el final')
    //     }
    // }

    // onFinishFailedDesplejar() {
    //     message.error('Complete los datos para agregar los marbetes')
    // }

    render() {
        return (
            <>
                <ModalItem
                    title="Elegir Marbetes"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarElegirMarbetes.bind(this)}
                    paddingTop
                    width={700}
                >
                    <Form
                        ref={this.formRef}
                        name="elegirMarbetes"
                        layout
                        bottom={
                            <ButtonItem
                                type="primary"
                                htmlType="submit"
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Elegir
                            </ButtonItem>
                        }
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <FormInicial.List name="marbetes">
                            {(fields) => (
                                <>
                                    <table className="table">
                                        {fields.length > 0 ? (
                                            <thead>
                                                <tr>
                                                    <th className="th-border" style={{ width: "20%" }}>
                                                        Numero
                                                    </th>
                                                    <th className="th-border" style={{ width: "20%" }}>
                                                        Numero 2
                                                    </th>
                                                    <th className="th-border" style={{ width: "20%" }}>
                                                        Cantidad
                                                    </th>
                                                    <th className="th-border" style={{ width: "20%" }}>
                                                        Cantidad Disponible
                                                    </th>
                                                    <th className="th-border" style={{ width: "20%" }}>
                                                        Cantidad Requerida
                                                    </th>
                                                </tr>
                                            </thead>
                                        ) : null}
                                        {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                                                <tbody key={arrayKey}>
                                                    <tr>
                                                        <td className="td-border">
                                                            <p style={{margin: 0, marginLeft: 10}}>{this.state.marbetes[arrayKey].numeroMarbete}</p>
                                                        </td>
                                                        <td className="td-border">
                                                        <p style={{margin: 0, marginLeft: 10}}>{this.state.marbetes[arrayKey].numero2Marbete}</p>
                                                        </td>
                                                        <td className="td-border">
                                                        <p style={{margin: 0, marginLeft: 10}}>{this.state.marbetes[arrayKey].cantidad}</p>
                                                        </td>
                                                        <td className="td-border">
                                                        <p style={{margin: 0, marginLeft: 10}}>{round10Decimals(this.state.marbetes[arrayKey].cantidad - this.state.marbetes[arrayKey].cantidadVendida)}</p>
                                                        </td>
                                                        <td className="td-border">
                                                        <FormItem
                                                            noStyle
                                                            shouldUpdate={(prev, curr) =>
                                                                prev.marbetes !==
                                                                curr.marbetes
                                                            }
                                                        >
                                                            {({ getFieldValue }) => {
                                                                const marbetes = getFieldValue("marbetes");
                                                                const cantidadVendida =
                                                                    marbetes[arrayKey].cantidadVendida;
                                                                    const cantidad =
                                                                    marbetes[arrayKey].cantidad;
                                                                let max = round10Decimals(cantidad - cantidadVendida);
                                                                return (
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "cantidadRequerida"]}
                                                                        numberCero
                                                                        noStyle
                                                                        type="number"
                                                                        max={max}
                                                                    >
                                                                        <InputNumberItem
                                                                            sinBorde
                                                                            placeholder={
                                                                                max && max !== Infinity
                                                                                    ? "Max: " + max
                                                                                    : "Cantidad"
                                                                            }
                                                                        />
                                                                    </FormItem>
                                                                );
                                                            }}
                                                        </FormItem>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        )}
                                    </table>

                                </>
                            )}
                        </FormInicial.List>
                    </Form>
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(ElegirMarbetes);

import {  PageHeader, Space, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { ButtonItem, TableItem, dineroDisabledMask } from "../../../Components/Items";

moment.locale("es");

class Cotizaciones extends Component {
    state = {
        todasCotizaciones: [],
        loading: false,
        modalCancelacion: false,
        modalCancelacionId: null,
        modalEnvios: false,
        modalEnviosId: null
    };
    componentDidMount() {
        this.consultarCotizaciones();
    }

    consultarCotizaciones() {
        this.setState({ loading: true });
        axios
            .post(
                "cotizaciones/selectCotizaciones",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasCotizaciones: res.data.map((value) => {
                            let numero = value.cotizacionSerie+' '+value.cotizacionNumero
                            return {
                                ...value,
                                fechaCotizacion: value.fechaCotizacion,
                                numero: numero,
                                cliente: (value.cliente.persona ? value.cliente.razonSocial : (value.cliente.nombre + ' ' + value.cliente.apellidoPaterno + ' ' + value.cliente.apellidoMaterno)) + " - " + value.cliente.rfc,
                                accion: {
                                    _id: value._id,
                                    estado: value.estado,
                                    servicios: value.servicios,
                                },
                            };
                        }),
                    });
                }
            });
    }

    agregarCotizacion() {
        this.props.history.push("/panel/agregarCotizacion");
    }
    render() {
        const columns = [
            {
                title: "Numero",
                dataIndex: "numero",
                key: "numero",
                buscar: 'numero',
                width: "7%",
            },
            {
                title: "Proyecto",
                dataIndex: "proyecto",
                key: "proyecto",
                sorter: (a, b) => {
                    if (a.proyecto.nombre < b.proyecto.nombre) {
                        return -1;
                    }
                    if (a.proyecto.nombre > b.proyecto.nombre) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => value.nombre,
                width: "16%",
                ellipsis: true,
            },
            {
                title: "Cliente",
                dataIndex: "cliente",
                key: "cliente",
                sorter: (a, b) => {
                    if (a.cliente < b.cliente) {
                        return -1;
                    }
                    if (a.cliente > b.cliente) {
                        return 1;
                    }
                    return 0;
                },
                width: "16%",
                buscar: 'cliente',
                ellipsis: true,
            },
            {
                title: "Fecha",
                dataIndex: "fechaCotizacion",
                key: "fechaCotizacion",
                sorter: (a, b) => {
                    if (moment(a.fechaCotizacion) < moment(b.fechaCotizacion)) {
                        return -1;
                    }
                    if (moment(a.fechaCotizacion) > moment(b.fechaCotizacion)) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => moment(value).locale("es").format("ll"),
                wdith: "13%",
                ellipsis: true,
                align: "center",
            },
            {
                title: "Subtotal",
                dataIndex: "subtotal",
                key: "subtotal",
                sorter: (a, b) => {
                    if (a.subtotal < b.subtotal) {
                        return -1;
                    }
                    if (a.subtotal > b.subtotal) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                align: "right",
                buscar: "subtotal",
                width: "8%",
                ellipsis: true,
            },
            {
                title: "Total",
                dataIndex: "total",
                key: "total",
                sorter: (a, b) => {
                    if (a.total < b.total) {
                        return -1;
                    }
                    if (a.total > b.total) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                buscar: "total",
                width: "8%",
                ellipsis: true,
                align: "right",
            },
            {
                title: "Estado",
                dataIndex: "estado",
                key: "estado",
                render: (value) => {
                    if (value === 0) {
                        return <Tag color="red">Cancelada</Tag>;
                    }
                    if (value === 1) {
                        return <Tag color="blue">Sin entregar</Tag>;
                    }
                    if (value === 2) {
                        return <Tag color="orange">Entregado parcialmente</Tag>;
                    }
                    if (value === 3) {
                        return <Tag color="green">Entregado</Tag>;
                    }
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Acción",
                dataIndex: "accion",
                key: "accion",
                render: (value, index) => {
                    if (value.estado === 0) return;

                    let buttons = [];
                    if (
                        (value.estado === 1 || value.estado === 2) &&
                        this.props.permisoEntregarInsumosVenta
                    ) {
                        buttons.push(
                            <ButtonItem
                                type="default"
                                size="small"
                                onClick={() => this.entregarInsumosVenta(value._id)}
                            >
                                Entregar Insumos
                            </ButtonItem>
                        );
                    }
                    return <Space direction="horizontal">{buttons}</Space>;
                },
                width: "22%",
            },
        ];
        return (
            <>
                <PageHeader
                    title="Cotizaciones"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={
                        <ButtonItem
                            type="primary"
                            key="agregarCotizacion"
                            onClick={this.agregarCotizacion.bind(this)}
                        >
                            Agregar Cotizacion
                        </ButtonItem>
                    }
                />
                <TableItem
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: (event) => {
                                this.props.history.push("/panel/verCotizacion", { id: record._id });
                            },
                        };
                    }}
                    loading={this.state.loading}
                    size="small"
                    dataSource={this.state.todasCotizaciones}
                    columns={columns}
                    pagination={false}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(Cotizaciones);

import {
  PageHeader,
  Spin,
  Select,
  Form as FormInitial,
  Divider,
  Row,
  Col,
  Switch,
  Input,
  Space,
  message,
  Popconfirm,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  cuentaMask,
  InputItem,
  ButtonItem,
  NotificacionPolizas,
} from "../../../../Components/Items";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

class VerOrdenAgregarInventario extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    todasInsumos: null,
    objetoInsumos: null,
    todasCuentaContable: null,
    objetoCuentaContable: null,
    modalAgregarCuentaContable: false,
    numOrden: 0,
    estadoOrden: 0,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "insumos/selectInsumosInventariables",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "agregarInventario/datosOrdenAgregarInventario",
        {
          empresaId: this.props.empresaId,
          ordenId: this.props.location.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.formRef.current.setFieldsValue(res.data);
          this.setState({ numOrden: res.data.numero, estadoOrden: res.data.estado });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }

  async onChangeInsumo(value, index) {
    if (this.formRef.current.getFieldValue("contabilizar")) {
      let arrayValue = await this.formRef.current.getFieldValue("insumos");
      const insumo = this.state.todasInsumos.find(
        (valueInsumos) => valueInsumos._id === arrayValue[index].insumoId
      );
      if (!insumo) return;

      arrayValue[index].costo = insumo.costo;

      this.formRef.current.setFieldsValue({ insumos: arrayValue });
    }
  }

  confirmarAgregarInventario() {
    axios
      .post(
        "agregarInventario/confirmarOrdenAgregarInventario",
        {
          ordenId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Inventario agregado");
          NotificacionPolizas(res.data.polizasId)
        } else {
          message.error("Sucedio un error y no se pudo agregar");
        }
        this.consultarTodo();
      });
  }

  cancelarOrdenAgregarInventario() {
    axios
      .post(
        "agregarInventario/cancelarOrdenAgregarInventario",
        {
          ordenId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Orden cancelada");
        } else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.consultarTodo();
      });
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Ver Orden - Agregar Inventario : " + this.state.numOrden}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="verOrdenAgregarInventario"
              layout
              bottom={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.estadoOrden === 1 && (
                      <Popconfirm
                        title={
                          "Seguro que quieres confirmar esta orden de agregar inventario"
                        }
                        onConfirm={() => this.confirmarAgregarInventario()}
                        okText="Si"
                        cancelText="No"
                      >
                        <ButtonItem type="primary">Confirmar</ButtonItem>
                      </Popconfirm>
                    )}
                    {this.state.estadoOrden === 1 && (
                      <Popconfirm
                        title={
                          "Seguro que quieres cancelar esta orden de agregar inventario"
                        }
                        onConfirm={() => this.cancelarOrdenAgregarInventario()}
                        okText="Si"
                        cancelText="No"
                      >
                        <ButtonItem type="primary" danger>
                          Cancelar
                        </ButtonItem>
                      </Popconfirm>
                    )}
                    <ButtonItem onClick={this.atras.bind(this)}>
                      Regresar
                    </ButtonItem>
                  </Space>
                </div>
              }
            >
              <Row>
                <Col span={12}>
                  <FormItem label="Proyecto" name="proyectoId" margin required>
                    <SelectItem disabled placeholder="Proyecto">
                      {this.state.objetoProyectos}
                    </SelectItem>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="referencia" label="Referencia" margin pattern>
                    <InputItem disabled placeholder="Referencia" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <FormItem
                    label="Contabilizar"
                    name="contabilizar"
                    margin
                    initialValue={false}
                    valuePropName="checked"
                  >
                    <Switch disabled />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const contabilizar = getFieldValue("contabilizar");
                      if (contabilizar) {
                        return (
                          <FormItem label="Cuenta Abono" margin>
                            <Input.Group compact>
                              <FormItem name="cuentaAbonoId" required noStyle>
                                <SelectItem
                                  disabled
                                  placeholder="Cuenta Abono"
                                  width="80%"
                                >
                                  {this.state.objetoCuentaContable}
                                </SelectItem>
                              </FormItem>
                              <ButtonItem
                                icon={<PlusOutlined />}
                                style={{ width: "20%" }}
                                disabled
                              />
                            </Input.Group>
                          </FormItem>
                        );
                      }
                    }}
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumos"
              />
              <FormInitial.List name="insumos">
                {(fields, { add, remove }) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "60%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Costo
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Cantidad
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  fieldKey={[arrayKey, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    onChange={(value) => {
                                      this.onChangeInsumo(value, arrayKey);
                                    }}
                                    sinBorde
                                    autoFocus
                                    width="100%"
                                    placeholder="Insumo"
                                    disabled
                                  >
                                    {this.state.objetoInsumos}
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "costo"]}
                                  fieldKey={[arrayKey, "costo"]}
                                  number
                                  noStyle
                                  required
                                  initialValue={0}
                                >
                                  <InputNumberItem disabled sinBorde />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidad"]}
                                  fieldKey={[arrayKey, "cantidad"]}
                                  number
                                  noStyle
                                  required
                                  initialValue={undefined}
                                >
                                  <InputNumberItem disabled sinBorde />
                                </FormItem>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                  </>
                )}
              </FormInitial.List>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VerOrdenAgregarInventario);

import { message, Input, Select, Row, Col, Checkbox } from "antd";
// import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  InputNumberItem,
  ButtonItem,
  InputItem,
} from "../../../../Components/Items";
import axios from "axios";
import ListadoCuentas from "./ListadoCuentas/ListadoCuentas";

const { Option } = Select;

class AgregarCuentaContable extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoSubtipo: null,
    todasSubtipo: null,
    objetoAgrupadorSat: null,
    todasAgrupadorSat: null,
    objetoMonedas: null,
    todasMonedas: null,
    modalAgregarSubtipo: false,
    modalListadoCuentas: false,
    validateStatus: "success",
    errorMsg: "Comieza por el primer nivel para agregar mas (001-999)",
    descripcionAgrupador: ''
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarAgregarCuentaContable(codigo) {
    this.props.cerrarAgregarCuentaContable(codigo);
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post("agrupadorsat/selectAgrupadorSat", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasAgrupadorSat: res.data });
          this.setState({
            objetoAgrupadorSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.subtipoCodigo !== ""
                    ? value.codigo +
                      " - " +
                      value.subtipo +
                      " - " +
                      value.descripcion
                    : value.codigo +
                      " - " +
                      value.tipo +
                      " - " +
                      value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("monedas/selectMonedas", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMonedas: res.data });
          this.setState({
            objetoMonedas: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    if (this.state.validateStatus !== "success")
      return message.error("Porfavor ingrese bien los datos");
    this.setState({ loading: true });
    if (this.state.error === 1) {
      this.setState({ loading: false });
      return message.error("No se puede registrar el subtipo con ese codigo");
    }
    axios
      .post(
        "cuentascontables/agregarCuentaContable",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado una cuenta contable");
          this.formRef.current.resetFields();
          this.cerrarAgregarCuentaContable(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede registrar la cuenta con ese codigo");
        } else {
          message.error("Hubo un error y la cuenta no se registro");
        }
      });
  }
  abrirAgregarSubtipo() {
    this.setState({ modalAgregarSubtipo: true });
  }
  cerrarAgregarSubtipo(codigo) {
    this.setState({ modalAgregarSubtipo: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ subtipoId: codigo });
      }
    });
  }
  abrirListadoCuentas() {
    this.setState({ modalListadoCuentas: true });
  }
  cerrarListadoCuentas() {
    this.setState({ modalListadoCuentas: false });
    this.consultarTodo()
  }
  onBlurNivel1() {}
  onChangeNivel1(value) {
    if (value === null) return;
    if (value.toString().length < 3) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 3 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 3) {
      axios
        .post(
          "cuentascontables/checarNivel1",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel1 === 1) {
            this.setState({
              errorMsg: "Esta cuenta ya existe",
              validateStatus: "error",
            });
          } else if (res.data.existeNivel1 === 0) {
            this.setState({
              errorMsg: "",
              validateStatus: "success",
            });
          }
        });
    } else if (value.toString().length < 5) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 5 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 5) {
      axios
        .post(
          "cuentascontables/checarNivel2",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel1 === 1) {
            if (res.data.existeNivel2 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel2 === 0) {
              this.setState({
                errorMsg: "",
                validateStatus: "success",
              });
            }
          } else if (res.data.existeNivel1 === 0) {
            this.setState({
              errorMsg: "El nivel 1 no existe, primero registre el nivel 1.",
              validateStatus: "error",
            });
          }
        });
    } else if (value.toString().length < 8) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 8 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 8) {
      axios
        .post(
          "cuentascontables/checarNivel3",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel12 === 1) {
            if (res.data.existeNivel3 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel3 === 0) {
              this.setState({
                errorMsg: "",
                validateStatus: "success",
              });
            }
          } else if (res.data.existeNivel12 === 0) {
            this.setState({
              errorMsg:
                "Los primeros niveles no existen, registre primero los niveles.",
              validateStatus: "error",
            });
          }
        });
    } else if (value.toString().length < 11) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 11 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 11) {
      axios
        .post(
          "cuentascontables/checarNivel4",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel123 === 1) {
            if (res.data.existeNivel4 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel4 === 0) {
              this.setState({
                errorMsg: "",
                validateStatus: "success",
              });
            }
          } else if (res.data.existeNivel123 === 0) {
            this.setState({
              errorMsg:
                "Los primeros niveles no existen, registre primero los niveles.",
              validateStatus: "error",
            });
          }
        });
    } else if (value.toString().length < 14) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 14 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 14) {
      axios
        .post(
          "cuentascontables/checarNivel5",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel1234 === 1) {
            if (res.data.existeNivel5 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel5 === 0) {
              this.setState({
                errorMsg: "",
                validateStatus: "success",
              });
            }
          } else if (res.data.existeNivel1234 === 0) {
            this.setState({
              errorMsg:
                "Los primeros niveles no existen, registre primero los niveles.",
              validateStatus: "error",
            });
          }
        });
    }
  }
  onChangeAgrupador(value){
    if(!this.state.todasAgrupadorSat) return
    const agrupador = this.state.todasAgrupadorSat.find(valueTodas => valueTodas._id === value)
    if(!agrupador) return
    const message = `${agrupador.tipoCodigo}.${agrupador.subtipoCodigo} ${agrupador.tipo} - ${agrupador.subtipo} : ${agrupador.acreedora ? 'Acreedora' : 'Deudora'}`
    this.setState({descripcionAgrupador: message})
    this.formRef.current.setFieldsValue({descripcion: agrupador.descripcion})
    const cuenta = this.formRef.current.getFieldValue('cuenta')
    if(cuenta && cuenta.length !== 3 && cuenta.length !== 5) return
    const nuevaCuenta = agrupador.codigo.toString().replace('.', '')
    this.formRef.current.setFieldsValue({cuenta: nuevaCuenta})
    this.onChangeNivel1(nuevaCuenta)
  }
  render() {
    return (
      <>
        <ModalItem
          title="Agregar Cuenta Contable"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarAgregarCuentaContable.bind(this)}
          paddingTop
          width={950}
        >
          <Form
            ref={this.formRef}
            name="agregarCuentaContable"
            layoutEspecial
            bottom={
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Agregar
              </ButtonItem>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
            initialValues={{monedaId: '60c7e4396465ad440c41c15a'}}
          >
            <FormItem
              label="Agrupador SAT"
              margin
              name="agrupadorSatId"
              required
              help={this.state.descripcionAgrupador}
            >
              <SelectItem placeholder="Agrupador SAT" onChange={this.onChangeAgrupador.bind(this)}>
                {this.state.objetoAgrupadorSat}
              </SelectItem>
            </FormItem>
            <FormItem
              label="Numero de Cuenta"
              margin
              validateStatus={this.state.validateStatus}
              help={this.state.errorMsg}
              tooltip={{ title: "Niveles de 000-00-000-000-000" }}
            >
              <Input.Group compact>
                <FormItem name="cuenta" required noStyle number>
                  <InputNumberItem
                    placeholder="Numero de Cuenta"
                    cuentaContable
                    onBlur={this.onBlurNivel1.bind(this)}
                    onChange={this.onChangeNivel1.bind(this)} 
                    style={{width: '80%'}}
                  />
                </FormItem>
                <ButtonItem
                  children='Ver listado'
                  type='primary'
                  style={{ width: "20%" }}
                  onClick={this.abrirListadoCuentas.bind(this)}
                />
              </Input.Group>
            </FormItem>
            <Row style={{ width: "100%" }}>
              <Col span={12}>
                <FormItem
                  name="descripcion"
                  label="Descripcion"
                  required
                  max={200}
                  margin
                  pattern
                >
                  <InputItem placeholder="Descripcion" />
                </FormItem>
                <FormItem
                  label="Tipo de Moneda"
                  name="monedaId"
                  required
                  margin
                >
                  <SelectItem placeholder="Tipo de Moneda">
                    {this.state.objetoMonedas}
                  </SelectItem>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="cuentaIva"
                  label="Cuenta IVA"
                  specialLayout
                  margin
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </ModalItem>
        <ListadoCuentas           visible={this.state.modalListadoCuentas}
          cerrarListadoCuentas={this.cerrarListadoCuentas.bind(this)} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarCuentaContable);

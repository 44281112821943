import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader, Popconfirm, Space, Tag, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem,ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

class Requisiciones extends Component {
  state = {
    idEditarRequisicion: null,
    modalAgregarFamilia: false,
    todasRequisiciones: [],
    loading: false,
  };
  componentDidMount() {
    this.consultarRequisiciones();
  }
  consultarRequisiciones() {
    this.setState({ loading: true });
    axios
      .post(
        "requisiciones/selectTodasRequisiciones",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasRequisiciones: res.data.map((data) => {
              return {
                ...data,
                accion: {
                  estado: data.estado,
                  _id: data._id,
                },
                proyecto: data.proyecto.nombre,
              };
            }),
          });
        }
      });
  }

  cancelarRequisicion(value) {
    axios
      .post(
        "requisiciones/cancelarRequisicion",
        { requisicionId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Requisicion cancelada");
        } else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.consultarRequisiciones();
      });
  }
  agregarRequisicion() {
    this.props.history.push("/panel/agregarRequisicion");
  }
  editarRequisicion(id) {
    this.props.history.push("/panel/editarRequisicion", { id });
  }
  generarOrdenDeCompra(id) {
    this.props.history.push("/panel/generarOrdenDeCompraConRequisicion", { id });
  }
  abrirAgregarFamilia() {
    this.setState({ modalAgregarFamilia: true });
  }
  cerrarAgregarFamilia() {
    this.setState({ modalAgregarFamilia: false });
    this.consultarRequisiciones();
  }
  render() {
    const columns = [
      {
        title: "Numero",
        dataIndex: "numero",
        key: "numero",
        width: "10%",
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('L'),
        width: '10%',
      },
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        sorter: (a, b) => {
          if (a.proyecto < b.proyecto) {
            return -1;
          }
          if (a.proyecto > b.proyecto) {
            return 1;
          }
          return 0;
        },
        width: "10%",
      },
      {
        title: "Observaciones",
        dataIndex: "observaciones",
        key: "observaciones",
        sorter: (a, b) => {
          if (a.observaciones < b.observaciones) {
            return -1;
          }
          if (a.observaciones > b.observaciones) {
            return 1;
          }
          return 0;
        },
        buscar: "observaciones",
        width: "15%",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (value, index) => {
          if (value === 0) {
            return <Tag color="red">Cancelada</Tag>;
          }
          if (value === 1) {
            return <Tag color="orange">Pendiente</Tag>;
          }
          if (value === 2) {
            return <Tag color="lime">En proceso</Tag>;
          }
          if (value === 3) {
            return <Tag color="green">Terminada</Tag>;
          }
        },
        width: "10%",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value, index) => (
          <Space size="middle">
            {(value.estado === 1 || value.estado === 2) ?
                  <ButtonItem
                  type="primary"
                  size="small"
                  onClick={() => this.generarOrdenDeCompra(value._id)}
                >
                  Generar Orden
                </ButtonItem>
            : null}
            {value.estado === 1 ? (
              <>
                <EditOutlined
                  onClick={() => this.editarRequisicion(value._id)}
                  style={{ color: "blue" }}
                />
                <Popconfirm
                  title="Seguro que quieres cancelar esta requisicion"
                  onConfirm={() => this.cancelarRequisicion(value._id)}
                  okText="Si"
                  cancelText="No"
                  key={index.toString()}
                >
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Popconfirm>
              </>
            ) : null}
          </Space>
        ),
        width: "15%",
      },
    ];
    return (
      <>
        <PageHeader
          title="Requisiciones"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarRequisicion"
              onClick={this.agregarRequisicion.bind(this)}
            >
              Agregar Requisicion
            </ButtonItem>
          }
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasRequisiciones}
          columns={columns}
          pagination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Requisiciones);

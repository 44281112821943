import {
  Col,
  message,
  PageHeader,
  Row,
  Spin,
  Select,
  Space,
  Tabs,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { connect } from "react-redux";
import {
  SelectItem,
  Form,
  FormItem,
  cuentaMask,
  TableItem,
  DatePickerItem,
  dineroDisabledMask,
  round2Decimals,
  ButtonItem
} from "../../../../Components/Items";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import reporteDeEstadoDePosicionFinancieraPdf from "../../../../Pdf/reporteDeEstadoDePosicionFinancieraPdf";

moment.locale("es");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;
const { TabPane } = Tabs;

class ReporteEstadoDePosicionFinanciera extends Component {
  state = {
    loading: false,
    pdf: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoUniadesDeNegocio: null,
    todasUniadesDeNegocio: null,
    dataReporte: null,
    todasActivo: [],
    todasPasivo: [],
    todasCapital: [],
  };

  atras() {
    this.props.history.goBack();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocio",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocio: res.data });
          this.setState({
            objetoUniadesDeNegocio: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    this.consultarReporteEstadoDePosicionFinanciera(
        {
            fechaFinal: moment(),
            nivel: 3
        }
    );
  }

  consultarReporteEstadoDePosicionFinanciera(values) {
    this.setState({ loading: true });
    axios
      .post(
        "reportesFinancieros/reporteEstadoDePosicionFinanciera",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({
            loading: false,
            dataReporte: res.data,
            todasActivo: res.data.arrayActivo.map(valueCuenta => {
                return {
                    ...valueCuenta,
                    cuenta: cuentaMask(valueCuenta.nivel1+valueCuenta.nivel2+valueCuenta.nivel3+valueCuenta.nivel4+valueCuenta.nivel5)
                }
            }),
            todasPasivo: res.data.arrayPasivo.map(valueCuenta => {
                return {
                    ...valueCuenta,
                    cuenta: cuentaMask(valueCuenta.nivel1+valueCuenta.nivel2+valueCuenta.nivel3+valueCuenta.nivel4+valueCuenta.nivel5)
                }
            }),
            todasCapital: res.data.arrayCapital.map(valueCuenta => {
                return {
                    ...valueCuenta,
                    cuenta: cuentaMask(valueCuenta.nivel1+valueCuenta.nivel2+valueCuenta.nivel3+valueCuenta.nivel4+valueCuenta.nivel5)
                }
            }),
          });
        } else if (res.data.success === 1) {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        } else {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        }
        this.setState({ loading: false });
      });
  }

  descargarExcel() {
    let datosDescargar = []

    datosDescargar.push({
        Cuenta: '',
        Descripcion: '',
        Importe: ''
    })
    datosDescargar.push({
        Cuenta: '',
        Descripcion: '',
        Importe: ''
    })

    datosDescargar.push({
        Cuenta: '',
        Descripcion: 'ACTIVO',
        Importe: ''
    })

    for (const valueCuenta of this.state.todasActivo) {
        datosDescargar.push({
            Cuenta: cuentaMask(valueCuenta.nivel1+valueCuenta.nivel2+valueCuenta.nivel3+valueCuenta.nivel4+valueCuenta.nivel5),
            Descripcion: valueCuenta.descripcion,
            Importe: round2Decimals(valueCuenta.saldo)
        })
    }

    datosDescargar.push({
        Cuenta: '',
        Descripcion: '',
        Importe: ''
    })
    datosDescargar.push({
        Cuenta: '',
        Descripcion: '',
        Importe: ''
    })

    datosDescargar.push({
        Cuenta: '',
        Descripcion: 'PASIVO',
        Importe: ''
    })

    for (const valueCuenta of this.state.todasPasivo) {
        datosDescargar.push({
            Cuenta: cuentaMask(valueCuenta.nivel1+valueCuenta.nivel2+valueCuenta.nivel3+valueCuenta.nivel4+valueCuenta.nivel5),
            Descripcion: valueCuenta.descripcion,
            Importe: round2Decimals(valueCuenta.saldo)
        })
    }  

    datosDescargar.push({
        Cuenta: '',
        Descripcion: '',
        Importe: ''
    })
    datosDescargar.push({
        Cuenta: '',
        Descripcion: '',
        Importe: ''
    })

    datosDescargar.push({
        Cuenta: '',
        Descripcion: 'CAPITAL',
        Importe: ''
    })

    for (const valueCuenta of this.state.todasCapital) {
        datosDescargar.push({
            Cuenta: cuentaMask(valueCuenta.nivel1+valueCuenta.nivel2+valueCuenta.nivel3+valueCuenta.nivel4+valueCuenta.nivel5),
            Descripcion: valueCuenta.descripcion,
            Importe: round2Decimals(valueCuenta.saldo)
        })
    }  

    const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Estado de Posicion Financiera");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workbook,
      `Estado_de_Posicion_Financiera_${moment().locale("es").format("L")}.xlsx`
    );
  }

  descargarPdf() {
    pdfMake
      .createPdf(reporteDeEstadoDePosicionFinancieraPdf(this.state.dataReporte))
      .download(`Estado_de_Posicion_Financiera_${moment().locale("es").format("L")}`);
  }

  onFinishFailed(e) {
    console.log(e);
    message.error("Porfavor ingrese bien los datos");
  }

  render() {
    const columns = [
      {
        title: "Cuenta",
        dataIndex: "cuenta",
        key: "cuenta",
        sorter: (a, b) => {
          if (a.cuenta < b.cuenta) {
            return -1;
          }
          if (a.cuenta > b.cuenta) {
            return 1;
          }
          return 0;
        },
        buscar: "cuenta",
        ellipsis: true,
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        ellipsis: true,
      },
      {
        title: "Importe",
        dataIndex: "saldo",
        key: "saldo",
        sorter: (a, b) => {
          if (a.saldo < b.saldo) {
            return -1;
          }
          if (a.saldo > b.saldo) {
            return 1;
          }
          return 0;
        },
        buscar: "saldo",
        render: (value) => dineroDisabledMask(value),
        align: 'right',
        ellipsis: true,
      },
    ];
    return (
      <>
        <Form
          ref={this.formRef}
          name="rerpoteEstadoDePosicionFinanciera"
          onFinish={this.consultarReporteEstadoDePosicionFinanciera.bind(this)}
          onFinishFailed={this.onFinishFailed}
            inita
        >
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Reporte Estado de Posicion Financiera"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Space>
                  {this.state.dataReporte && (
                    <ButtonItem
                      blanco
                      onClick={() => this.descargarExcel()}
                      icon={<FileExcelOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar Excel
                    </ButtonItem>
                  )}
                  {this.state.dataReporte && (
                    <ButtonItem
                      blanco
                      onClick={() => this.descargarPdf()}
                      icon={<FilePdfOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar PDF
                    </ButtonItem>
                  )}
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Consultar
                  </ButtonItem>
                </Space>
              </div>
            }
          />
          <Spin spinning={this.state.loading}>
            <div
              style={{ backgroundColor: "white", padding: 20, paddingTop: 5, marginBottom: 20 }}
            >
              <Tabs
                style={{ margin: 0 }}
                tabBarExtraContent={{
                  left: (
                    <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                      Filtros:
                    </h1>
                  ),
                }}
              >
                <TabPane tabKey="3" key="3" forceRender tab="Fecha Final">
                  <FormItem marginBottom='0px' initialValue={moment()} label="Fecha Final" name="fechaFinal" required>
                    <DatePickerItem />
                  </FormItem>
                </TabPane>
                <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                  <Row>
                    <Col span={8}>
                      <FormItem
                        name="filtrarProyecto"
                        required
                        noStyle
                        initialValue={0}
                      >
                        <SelectItem width="90%" placeholder="Filtrar Proyecto">
                          <Option default value={0}>
                            Niguno
                          </Option>
                          <Option value={1}>Proyectos</Option>
                          <Option value={2}>Unidades de negocio</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const filtrarProyecto =
                            getFieldValue("filtrarProyecto");
                          if (filtrarProyecto === 1) {
                            return (
                              <FormItem
                                required
                                name="proyectos"
                                noStyle
                              >
                                <SelectItem
                                  width="90%"
                                  placeholder="Proyectos"
                                  mode="multiple"
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                          if (filtrarProyecto === 2) {
                            return (
                              <FormItem
                                required
                                name="unidadDeNegocioId"
                                noStyle
                              >
                                <SelectItem
                                  width="90%"
                                  placeholder="Unidades de Negocio"
                                >
                                  {this.state.objetoUniadesDeNegocio}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabKey="5" key="5" forceRender tab="Nivel">
                  <FormItem
                    label="Nivel"
                    name="nivel"
                    required
                    marginBottom='0px'
                    initialValue={3}
                  >
                    <SelectItem placeholder="Nivel">
                      <Option value={1}>Nivel 1</Option>
                      <Option value={2}>Nivel 2</Option>
                      <Option value={3}>Nivel 3</Option>
                      <Option value={4}>Nivel 4</Option>
                      <Option value={5}>Nivel 5</Option>
                    </SelectItem>
                  </FormItem>
                </TabPane>
              </Tabs>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <h1 style={{textAlign: 'center'}}>Activo</h1>
                <TableItem
                    columns={columns}
                    dataSource={this.state.todasActivo}
                    size="small"
                    pagination={false}
                    />
                </Col>
                <Col span={12}>
                <h1 style={{textAlign: 'center'}}>Pasivo</h1>
                <TableItem
              columns={columns}
              dataSource={this.state.todasPasivo}
              size="small"
              pagination={false}
            />
            <h1 style={{textAlign: 'center'}}>Capital</h1>
                        <TableItem
              columns={columns}
              dataSource={this.state.todasCapital}
              size="small"
              pagination={false}
            />
                </Col>
            </Row>
          </Spin>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ReporteEstadoDePosicionFinanciera);

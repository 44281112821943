import { Button, Col, message, Row, Space, Form as FormInitial, notification, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ModalItem,
    ButtonItem,
    Form,
    FormItem,
    InputItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import facturaGlobalPdf from "../../../../Pdf/facturaGlobalPdf";
import CancelarFacturaGlobal from "../CancelarFacturaGlobal/CancelarFacturaGlobal";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MandarCorreoFacturaGlobal extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        pdf: null,
        modalCancelacion: false,
        totalVentas: 0
    };
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "ventas/facturaGlobalPdf",
                { facturaGlobalId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    if (res.data?.correosEnvio?.length > 0) {
                        this.formRef.current.setFieldsValue({ correosEnvio: res.data.correosEnvio })
                    }
                    const pdf = pdfMake.createPdf(facturaGlobalPdf(res.data));
                    this.setState(
                        {
                            loading: false,
                            pdf: pdf,
                            totalVentas: res.data.totalVentas
                        },
                        () => {
                            pdf.open({}, window.frames["facturaGlobalPdf"]);
                        }
                    );
                } else if (res.data.success === 1) {
                    message.error(res.data.message ? res.data.message : "No se pudo registrar la factura global");
                    notification.warning({
                        description: res.data.description,
                        message: res.data.message,
                        duration: 0,
                    });
                } else {
                    message.error("Hubo un error y la factura global no se registro");
                }
            });
        this.setState({ loading: false });
    }
    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
        if (values?.correosEnvio?.length > 0) {
            this.setState({ loading: true });
            axios
                .post(
                    "ventas/enviarFacturaGlobal",
                    { ...values, facturaGlobalId: this.props.id, empresaId: this.props.empresaId },
                    {
                        headers: this.props.headersToken,
                    }
                )
                .then((res) => {
                    this.setState({ loading: false });
                    if (res.data.success === 2) {
                        message.success("Factura Global enviada");
                        this.formRef.current.resetFields();
                        this.cerrarMandarCorreoFacturaGlobal();
                    } else if (res.data.success === 1) {
                        message.error("No se pudo enviar la factura global a ese correo");
                    } else {
                        message.error("Sucedio un error y no se pudo enviar");
                    }
                });
        } else {
            message.info('Proporcione al menos un correo de envio')
        }
    }
    descargarComplemento() {
        axios
            .post(
                "ventas/facturaGlobalPdf",
                { facturaGlobalId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                var xmltext = res.data.respuestaFactura.data.cfdi;

                var filename = res.data.respuestaFactura.data.uuid + ".xml";
                var pom = document.createElement("a");
                var bb = new Blob([xmltext], { type: "text/plain" });

                pom.setAttribute("href", window.URL.createObjectURL(bb));
                pom.setAttribute("download", filename);

                pom.dataset.downloadurl = [
                    "text/plain",
                    pom.download,
                    pom.href,
                ].join(":");
                pom.draggable = true;
                pom.classList.add("dragout");

                pom.click();

                const pdf = facturaGlobalPdf(res.data)

                pdfMake.createPdf(pdf).download(res.data.respuestaFactura.data.uuid + ".pdf")
            });
    }
    cerrarMandarCorreoFacturaGlobal() {
        this.props.cerrarMandarCorreoFacturaGlobal();
        this.formRef.current.resetFields()
    }
    cancelarFacturaGlobal(value) {
        this.setState({ modalCancelacion: true });
    }
    cerrarCancelarFacturaGlobal() {
        this.setState({
            modalCancelacion: false,
        });
        this.consultarTodo();
    }
    render() {
        return (
            <>
                <ModalItem
                    title="Vista previa"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarMandarCorreoFacturaGlobal.bind(this)}
                    paddingTop
                >
                    <Form
                        ref={this.formRef}
                        name="mandarCorreoFacturaGlobal"
                        layout
                        width={700}
                        onCancel={this.cerrarMandarCorreoFacturaGlobal.bind(this)}
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <FormInitial.List
                            name="correosEnvio"
                            initialValue={[{ correo: '' }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ name }, arrayKey) => (
                                        <Row>
                                            <Col span={22}>
                                                <FormItem
                                                    name={[name, "correo"]}
                                                    fieldKey={[arrayKey, "correo"]}
                                                    label='Correo'
                                                    required
                                                    margin
                                                    email
                                                    pattern
                                                >
                                                    <InputItem style={{ width: '100%' }} placeholder="Correo" />
                                                </FormItem>
                                            </Col>
                                            <Col span={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(name)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                    {fields.length < 5 ?
                                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                icon={<PlusOutlined />}
                                            >
                                                Agregar
                                            </Button>
                                        </div>
                                        : null}
                                </>
                            )}
                        </FormInitial.List>
                        <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
                            <Space>
                                <ButtonItem
                                    type="primary"
                                    blanco={true}
                                    onClick={this.descargarComplemento.bind(this)}
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Descargar
                                </ButtonItem>
                                <ButtonItem
                                    type="primary"
                                    htmlType="submit"
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Mandar Correo
                                </ButtonItem>
                                <Popconfirm
                                    title={'Seguro que desea cancelar la factura global, se cancelara en '+ (this.state.totalVentas === 1 ? ' 1 venta' : (this.state.totalVentas +' ventas'))}
                                    onConfirm={() => this.cancelarFacturaGlobal()}
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <ButtonItem
                                        type="primary"
                                        danger
                                        style={{ display: "block", margin: "0 0 0 auto" }}
                                    >
                                        Cancelar
                                    </ButtonItem>
                                </Popconfirm>
                                <ButtonItem
                                    type="primary"
                                    blanco={true}
                                    onClick={this.cerrarMandarCorreoFacturaGlobal.bind(this)}
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Cerrar
                                </ButtonItem>
                            </Space>
                        </div>
                    </Form>
                    <iframe
                        title={"complemento"}
                        style={{ width: "100%", height: "100vh" }}
                        id="facturaGlobalPdf"
                        name="facturaGlobalPdf"
                    />
                </ModalItem>
                <CancelarFacturaGlobal
                    visible={this.state.modalCancelacion}
                    id={this.props.id}
                    cerrarCancelarFacturaGlobal={this.cerrarCancelarFacturaGlobal.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(MandarCorreoFacturaGlobal);

import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Select,
  Form as FormInitial,
  Checkbox,
  Upload,
  Space,
  notification,
} from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  round2Decimals,
  ModalItem,
  ButtonItem,
  InputItem,
  NotificacionPolizas,
  round6Decimals,
  HeaderItem,
  BoxItem
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import xmlToJson from "../../../../Components/xmlToJson";
import ElegirTipoDeProveedor from "../../Proveedores/ElegirTipoDeProveedor/ElegirTipoDeProveedor";
import AgregarMarbetes from "../../Marbetes/AgregarMarbetes/AgregarMarbetes";

const { Option } = Select;

moment.locale("es");

class AgregarFactura extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProveedores: null,
    todasProveedores: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: null,
    todasInsumos: null,
    objetoMonedas: null,
    todasMonedas: null,
    impuestos: [],
    modalConfirmacion: false,
    valuesForm: null,
    modalElegirTipoDeProveedor: false,
    modalElegirTipoDeProveedorId: null,
    modalElegirTipoDeProveedorNombre: null,
    archivosXml: [],
    empresaRfc: null,
    rendered: 0,
    ajusteMax: 0,
    modalAgregarMarbetes: false,
    modalAgregarMarbetesIndex: null,
    modalAgregarMarbetesMarbetes: null
  };
  async componentDidMount() {
    this.setState({ loading: true });
    await this.consultarTodo()
    if (this.props?.location?.state) {
      this.leerXmlData(this.props.location.state)
    }
    this.setState({ loading: false });
  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  async consultarTodo() {

    const responseConfiguracion = axios
      .post(
        "empresas/selectConfiguracionGeneralParaFacturas",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )

    const responseProveedores = axios
      .post(
        "proveedores/selectProveedores",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responseProyectos = axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responseInsumos = axios
      .post(
        "insumos/selectInsumosCompra",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responseMonedas = axios
      .post(
        "monedas/selectMonedas",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responses = await Promise.all([
      responseConfiguracion,
      responseProveedores,
      responseProyectos,
      responseInsumos,
      responseMonedas
    ])

    let newState = {}

    if (responses[0].data.status === 404) {
      message.error("Error no se encontro configuracion de esta empresa");
    } else {
      newState.empresaRfc = responses[0].data.rfc
      newState.ajusteMax = responses[0].data.ajusteMax
    }

    if (responses[1].data.status === 404) {
    } else {
      newState.todasProveedores = responses[1].data
      newState.objetoProveedores = responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      })
    }

    if (responses[2].data.status === 404) {
    } else {
      newState.todasProyectos = responses[2].data
      newState.objetoProyectos = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      })
    }

    if (responses[3].data.status === 404) {
    } else {
      newState.todasInsumos = responses[3].data
      newState.objetoInsumos = responses[3].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      })
    }

    if (responses[4].data.status === 404) {
    } else {
      newState.todasMonedas = responses[4].data
      newState.objetoMonedas = responses[4].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre + " - " + value.abreviacion}
          </Option>
        );
      })
    }

    await this.promisedSetState(newState)

  }
  onFinishFailed(e) {
    console.log(e)
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {

        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        )
      } else {
        message.error(
          "Hay un error en un campo"
        )
      }
      return null
    }
    );
  }
  onFinish(values) {
    this.setState({ loading: true });
    let insumos = values.insumos;
    let total = 0;
    let subtotal = 0;
    let arrayImpuestos = [];
    let arrayRetenciones = [];
    let cuentasProveedor = 0;
    insumos.map((value) => {
      total += value.subtotal;
      subtotal += value.subtotal;
      value.arrayImpuestos.map((valueImpuestos) => {
        let index = arrayImpuestos.findIndex(
          (valueImpuestosAnterios) =>
            valueImpuestosAnterios.descripcionFactura ===
            valueImpuestos.descripcionFactura
        );
        if (index !== -1) {
          let newItem = {
            totalImpuestos:
              arrayImpuestos[index].totalImpuestos +
              valueImpuestos.totalImpuestos,
            descripcionFactura: valueImpuestos.descripcionFactura,
          };
          arrayImpuestos.splice(index, 1, newItem);
        } else {
          arrayImpuestos.push({
            totalImpuestos: valueImpuestos.totalImpuestos,
            descripcionFactura: valueImpuestos.descripcionFactura,
          });
        }
        return null;
      });
      if (value.arrayRetenciones) {
        value.arrayRetenciones.map((valueRetenciones) => {
          let index = arrayRetenciones.findIndex(
            (valueRetencionesAnterios) =>
              valueRetencionesAnterios.descripcionFactura ===
              valueRetenciones.descripcionFactura
          );
          cuentasProveedor += round2Decimals(valueRetenciones.totalRetenciones);
          if (index !== -1) {
            let newItem = {
              totalRetenciones:
                arrayRetenciones[index].totalRetenciones +
                valueRetenciones.totalRetenciones,
              descripcionFactura:
                valueRetenciones.descripcionFactura,
            };
            arrayRetenciones.splice(index, 1, newItem);
          } else {
            arrayRetenciones.push({
              totalRetenciones: valueRetenciones.totalRetenciones,
              descripcionFactura:
                valueRetenciones.descripcionFactura,
            });
          }
          return null;
        });
      }
      return null;
    });
    subtotal += values.ajusteSubtotal
    total += values.ajusteSubtotal
    arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
      let ajusteImpuesto = values['ajuste' + valueImpuesto.descripcionFactura]
      total += round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto)
      return {
        ...valueImpuesto,
        ajusteImpuesto,
        totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto),
      }
    })
    arrayRetenciones = arrayRetenciones.map(valueRetencion => {
      let ajusteRetencion = values['ajuste' + valueRetencion.descripcionFactura]
      cuentasProveedor += ajusteRetencion
      return {
        ...valueRetencion,
        ajusteRetencion,
        totalRetenciones: (valueRetencion.totalRetenciones + ajusteRetencion),
      }
    })
    axios
      .post(
        "facturassinordendecompra/agregarFactura",
        {
          ...values,
          empresaId: this.props.empresaId,
          total: round2Decimals(total),
          subtotal: round2Decimals(subtotal),
          cuentasProveedor: round2Decimals(cuentasProveedor),
          totalPagar: round2Decimals(total - cuentasProveedor),
          arrayImpuestos,
          arrayRetenciones
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha registrado una factura"
          );
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.warning(res.data.message);
        } else {
          message.error("Hubo un error y la factura no se registro");
        }
      });
  }

  async onChangeInsumo(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === value
    );
    if (!insumo) return
    arrayValue[key].precioUnitario = insumo.costo;

    const precioUnitario = arrayValue[key].precioUnitario

    let totalImpuestosPrecioSinImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      let totalImpuesto
      if (valueImpuesto.tasaCuota === 1) {
        totalImpuesto = (precioUnitario * (valueImpuesto.monto / 100))
      } else if (valueImpuesto.tasaCuota === 2) {
        totalImpuesto = (precioUnitario + totalImpuestosPrecioSinImpuestos) * (valueImpuesto.monto / 100)
      } else if (valueImpuesto.tasaCuota === 3) {
        totalImpuesto = valueImpuesto.monto
      } else if (valueImpuesto.tasaCuota === 4) {
        let cantidad = arrayValue[key].cantidad
        if (cantidad) {
          totalImpuesto = (arrayValue[key].impuestos || 0) / arrayValue[key].cantidad
        }
      } else if (valueImpuesto.tasaCuota === 5) {
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto
      return null
    })
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(precioUnitario + totalImpuestosPrecioSinImpuestos)

    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento / 100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
              round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          } else if (valueImpuesto.tasaCuota === 4) {
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          } else if (valueImpuesto.tasaCuota === 5) {
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if (proveedorId) {
        //Consultar proveedor

        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
        );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []


        proveedor.retenciones.map((valueRetencion) => {
          //7
          let totalRetencion;
          if (valueRetencion.tasaCuota === 1) {
            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
          } else if (valueRetencion.tasaCuota === 2) {
            totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
          }
          totalRetenciones += totalRetencion;
          arrayRetenciones.push({
            descripcionFactura: valueRetencion.descripcionFactura,
            totalRetenciones: totalRetencion,
          });
          return null
        })

        insumo.retenciones.map((valueRetencion) => {
          if ((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3) {
            //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          }
          return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }

    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  async onChangePrecioCantidadDescuento(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
    );
    if (!insumo) return
    const precioUnitario = arrayValue[key].precioUnitario

    let totalImpuestosPrecioSinImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      let totalImpuesto
      if (valueImpuesto.tasaCuota === 1) {
        totalImpuesto = (precioUnitario * (valueImpuesto.monto / 100))
      } else if (valueImpuesto.tasaCuota === 2) {
        totalImpuesto = (precioUnitario + totalImpuestosPrecioSinImpuestos) * (valueImpuesto.monto / 100)
      } else if (valueImpuesto.tasaCuota === 3) {
        totalImpuesto = valueImpuesto.monto
      } else if (valueImpuesto.tasaCuota === 4) {
        let cantidad = arrayValue[key].cantidad
        if (cantidad) {
          totalImpuesto = (arrayValue[key].impuestos || 0) / arrayValue[key].cantidad
        }
      } else if (valueImpuesto.tasaCuota === 5) {
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto
      return null
    })
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(Number(precioUnitario) + Number(totalImpuestosPrecioSinImpuestos))
    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento / 100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
              round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          } else if (valueImpuesto.tasaCuota === 4) {
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          } else if (valueImpuesto.tasaCuota === 5) {
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if (proveedorId) {
        //Consultar proveedor

        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
        );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []

        proveedor.retenciones.map((valueRetencion) => {
          //7
          let totalRetencion;
          if (valueRetencion.tasaCuota === 1) {
            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
          } else if (valueRetencion.tasaCuota === 2) {
            totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
          }
          totalRetenciones += totalRetencion;
          arrayRetenciones.push({
            descripcionFactura: valueRetencion.descripcionFactura,
            totalRetenciones: totalRetencion,
          });
          return null
        })

        insumo.retenciones.map((valueRetencion) => {
          if ((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3) {
            //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          }
          return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  async onChangePrecioImpuestos(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
    );
    if (!insumo) return

    const precioUnitarioConImpuestos = arrayValue[key].precioUnitarioConImpuestos

    let totalPorcentajeImpuestos = 1
    let totalCantidadImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      if (valueImpuesto.tasaCuota === 1) {
        totalPorcentajeImpuestos += (valueImpuesto.monto / 100)
      } else if (valueImpuesto.tasaCuota === 2) {
        totalPorcentajeImpuestos += totalPorcentajeImpuestos * (valueImpuesto.monto / 100)
      } else if (valueImpuesto.tasaCuota === 3) {
        totalCantidadImpuestos = valueImpuesto.monto
      }
      return null
    })


    const precioUnitario = (precioUnitarioConImpuestos / totalPorcentajeImpuestos) + totalCantidadImpuestos
    arrayValue[key].precioUnitario = precioUnitario
    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento / 100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
              round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          } else if (valueImpuesto.tasaCuota === 4) {
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          } else if (valueImpuesto.tasaCuota === 5) {
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if (proveedorId) {
        //Consultar proveedor

        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
        );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []

        proveedor.retenciones.map((valueRetencion) => {
          //7
          let totalRetencion;
          if (valueRetencion.tasaCuota === 1) {
            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
          } else if (valueRetencion.tasaCuota === 2) {
            totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
          }
          totalRetenciones += totalRetencion;
          arrayRetenciones.push({
            descripcionFactura: valueRetencion.descripcionFactura,
            totalRetenciones: totalRetencion,
          });
          return null
        })

        insumo.retenciones.map((valueRetencion) => {
          if ((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3) {
            //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          }
          return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }

  async onChangeProveedor(value) {
    const proveedor = this.state.todasProveedores.find(valueProv => valueProv._id === value)
    if (!proveedor.tipoProveedorId) {
      const nombre = proveedor.persona ? proveedor.razonSocial : `${proveedor.nombre} ${proveedor.apellidoPaterno} ${proveedor.apellidoMaterno}`
      this.abrirElegirTipoDeProveedor(proveedor._id, nombre)
    }
    const fechaFactura = await this.formRef.current.getFieldValue("fechaFactura");
    this.formRef.current.setFieldsValue({ fechaPago: moment(fechaFactura).add(proveedor.diasCredito, 'days'), diasCredito: proveedor.diasCredito || 0 })
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    if (arrayValue?.length > 0) {
      arrayValue.map((valueInsumo, key) => this.onChangePrecioCantidadDescuento(undefined, key))
    }
    let newState = {}
    newState.objetoInsumos = this.state.todasInsumos.map((valueInsumo, index) => {

      let relacionInsumo

      if (Array.isArray(valueInsumo.relaciones)) {
        relacionInsumo = valueInsumo.relaciones.find(valueTodasRelaciones => valueTodasRelaciones.proveedorId.toString() === value.toString())
      }

      let nombreInsumo

      if (relacionInsumo) {
        let codigoRelacion = ''
        if (relacionInsumo.codigo !== 'undefined') {
          codigoRelacion = relacionInsumo.codigo + ' - '
        }
        nombreInsumo = codigoRelacion +
          relacionInsumo.descripcion +
          " - " +
          valueInsumo.unidad.nombre
      } else {
        nombreInsumo = valueInsumo.codigo +
          " - " +
          valueInsumo.descripcion +
          " - " +
          valueInsumo.unidad.nombre
      }

      return (
        <Option value={valueInsumo._id} key={valueInsumo._id}>
          {nombreInsumo}
        </Option>
      );
    })

    this.setState(newState)

  }
  atras() {
    this.props.history.push('/panel/facturassinordendecompra');
  }
  async actualizarBoton() {
    await this.consultarTodo()
    let arrayValue = this.formRef.current.getFieldValue("insumos");
    if (arrayValue?.length > 0) {
      arrayValue.map((valueInsumo, key) => this.onChangePrecioCantidadDescuento(undefined, key))
    }
  }
  abrirElegirTipoDeProveedor(id, nombre) {
    this.setState({ modalElegirTipoDeProveedor: true, modalElegirTipoDeProveedorId: id, modalElegirTipoDeProveedorNombre: nombre });
  }
  cerrarElegirTipoDeProveedor(elegido) {
    if (!elegido) {
      this.formRef.current.setFieldsValue({ proveedorId: undefined })
    }
    this.consultarTodo()
    this.setState({ modalElegirTipoDeProveedor: false, modalElegirTipoDeProveedorId: null, modalElegirTipoDeProveedorNombre: null });
  }

  async leerXml(e) {
    this.setState({ loading: true });
    if (e.file.type === "text/xml") {
      var reader = new FileReader();
      reader.onload = async (event) => {
        let insumosSinRegistrar = 0
        let insumos = []
        let proveedorId
        let fechaFactura
        let uuid
        let codigoFactura
        let tipoDeCambio
        let monedaId
        const hola = new xmlToJson().parseFromString(event.target.result);
        const comprobante = hola?.attributes;
        const emisor = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Emisor"
        )?.attributes;
        const complemento = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Complemento"
        );
        const timbre = complemento.children.find(
          (valueXml) => valueXml.name === "tfd:TimbreFiscalDigital"
        )?.attributes;
        const receptor = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Receptor"
        )?.attributes;
        const conceptos = hola.children.find(
          (valueXml) => valueXml.name === "cfdi:Conceptos"
        )?.children;

        if (
          receptor.Rfc?.toLowerCase() === this.state.empresaRfc?.toLowerCase()
        ) {
          if (
            !this.state.todasProveedores?.find(
              (valueTodos) =>
                valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
            )
          ) {
            this.setState({
              loading: false
            })
            notification.warning({
              message: 'El proveedor no esta registrado',
            })
            return this.props.history.push("/panel/leerXml", { fileName: e.file.name, fileData: JSON.stringify(hola), origin: '/panel/agregarFacturaSinOrdenDeCompra' })
          } else {
            const proveedor = this.state.todasProveedores?.find(
              (valueTodos) =>
                valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
            );
            proveedorId = proveedor._id
          }
        } else {
          this.setState({
            archivosXml: [
              {
                ...e.file,
                name: e.file.name,
                status: "success",
              },
            ],
            archivo: e.file,
            loading: false
          });
          return message.error(
            "El rfc del receptor no es igual al de la empresa"
          );
        }

        await Promise.all(conceptos.map(async (valueConcepto) => {
          const attr = valueConcepto.attributes;
          let totalImpuestos = 0
          valueConcepto.children
            .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
            ?.children.find(
              (valueTodos) => valueTodos.name === "cfdi:Traslados"
            )
            ?.children.map((valueImpuesto) => {
              const attrImpuestos = valueImpuesto.attributes;
              totalImpuestos += attrImpuestos.Importe
              return null
            });
          await axios
            .post(
              "insumos/buscarRelacion",
              {
                empresaId: this.props.empresaId,
                proveedorId: proveedorId,
                descripcion: attr.Descripcion,
                claveProdServ: attr.ClaveProdServ,
                claveUnidad: attr.ClaveUnidad,
                codigo: attr.NoIdentificacion,
              },
              {
                headers: this.props.headersToken,
              }
            )
            .then((res) => {
              if (res.data.status === 404) {
                insumosSinRegistrar += 1
                insumos.push({
                  precioUnitario: attr.ValorUnitario,
                  cantidad: attr.Cantidad,
                  descuento: attr.Descuento ? (Math.round(attr.Descuento / attr.Importe * 10000) / 100) : 0,
                  impuestos: totalImpuestos
                })
              } else {
                insumos.push({
                  insumoId: res.data._id,
                  precioUnitario: attr.ValorUnitario,
                  cantidad: attr.Cantidad,
                  descuento: attr.Descuento ? (Math.round(attr.Descuento / attr.Importe * 10000) / 100) : 0,
                  impuestos: totalImpuestos
                })
              }
            });
          return null
        }));

        fechaFactura = moment(comprobante.Fecha)
        codigoFactura = (comprobante.Serie ? comprobante.Serie : '') + ' - ' + (comprobante.Folio ? comprobante.Folio : '')
        uuid = timbre.UUID
        tipoDeCambio = comprobante.TipoCambio || 1

        if (insumosSinRegistrar > 0) {
          notification.warning({
            message: insumosSinRegistrar + ' insumos no estan registrados',
          })
          return this.props.history.push("/panel/leerXml", { fileName: e.file.name, fileData: JSON.stringify(hola), origin: '/panel/agregarFacturaSinOrdenDeCompra' })
        }
        if (proveedorId) {
          this.onChangeProveedor(proveedorId)
        }
        if (uuid) {
          this.onChangeUUID(uuid)
        }

        const moneda = this.state.todasMonedas?.find(
          (valueTodos) =>
            valueTodos.abreviacion?.toLowerCase() === comprobante.Moneda?.toLowerCase()
        );

        if (moneda) {
          monedaId = moneda._id
        } else {
          message.info('No encontramos la moneda del comprobante')
          monedaId = '60c7e4396465ad440c41c15a'
        }

        await this.formRef.current.setFieldsValue({ monedaId, tipoDeCambio, insumos, proveedorId, fechaFactura, codigoFactura, uuid })
        insumos.map((value, index) => this.onChangePrecioCantidadDescuento(null, index))
        this.setState({
          archivosXml: [
            {
              ...e.file,
              name: e.file.name,
              status: "success",
            },
          ],
          archivo: e.file,
          loading: false
        });
      };


      reader.readAsText(e.file);
    } else {
      message.error("El archivo debe ser un archivo de xml");
      this.setState({ loading: false });
    }
  }

  async leerXmlData(state) {
    await this.consultarTodo()
    this.setState({ loading: true });
    let insumosSinRegistrar = 0
    let insumos = []
    let proveedorId
    let fechaFactura
    let uuid
    let codigoFactura
    let tipoDeCambio
    let monedaId
    const hola = JSON.parse(state.fileData);
    const comprobante = hola?.attributes;
    const emisor = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Emisor"
    )?.attributes;
    const complemento = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Complemento"
    );
    const timbre = complemento.children.find(
      (valueXml) => valueXml.name === "tfd:TimbreFiscalDigital"
    )?.attributes;
    const receptor = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Receptor"
    )?.attributes;
    const conceptos = hola.children.find(
      (valueXml) => valueXml.name === "cfdi:Conceptos"
    )?.children;

    if (
      receptor.Rfc?.toLowerCase() === this.state.empresaRfc?.toLowerCase()
    ) {
      if (
        !this.state.todasProveedores?.find(
          (valueTodos) =>
            valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
        )
      ) {
        this.setState({
          loading: false
        })
        notification.warning({
          message: 'El proveedor no esta registrado',
        })
        return this.props.history.push("/panel/leerXml", { fileName: state.fileName, fileData: JSON.stringify(hola), origin: '/panel/agregarFacturaSinOrdenDeCompra' })
      } else {
        const proveedor = this.state.todasProveedores?.find(
          (valueTodos) =>
            valueTodos.rfc?.toLowerCase() === emisor.Rfc?.toLowerCase()
        );
        proveedorId = proveedor._id
      }
    } else {
      this.setState({
        archivosXml: [
          {
            name: state.fileName,
            status: "success",
          },
        ],
        archivo: state.fileData,
        loading: false
      });
      return message.error(
        "El rfc del receptor no es igual al de la empresa"
      );
    }

    await Promise.all(conceptos.map(async (valueConcepto) => {
      const attr = valueConcepto.attributes;
      let totalImpuestos = 0
      valueConcepto.children
        .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
        ?.children.find(
          (valueTodos) => valueTodos.name === "cfdi:Traslados"
        )
        ?.children.map((valueImpuesto) => {
          const attrImpuestos = valueImpuesto.attributes;
          totalImpuestos += attrImpuestos.Importe
          return null
        });
      await axios
        .post(
          "insumos/buscarRelacion",
          {
            empresaId: this.props.empresaId,
            proveedorId: proveedorId,
            descripcion: attr.Descripcion,
            claveProdServ: attr.ClaveProdServ,
            claveUnidad: attr.ClaveUnidad,
            codigo: attr.NoIdentificacion,
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
            insumosSinRegistrar += 1
            insumos.push({
              precioUnitario: attr.ValorUnitario,
              cantidad: attr.Cantidad,
              descuento: attr.Descuento ? (Math.round(attr.Descuento / attr.Importe * 10000) / 100) : 0,
              impuestos: totalImpuestos
            })
          } else {
            insumos.push({
              insumoId: res.data._id,
              precioUnitario: attr.ValorUnitario,
              cantidad: attr.Cantidad,
              descuento: attr.Descuento ? (Math.round(attr.Descuento / attr.Importe * 10000) / 100) : 0,
              impuestos: totalImpuestos
            })
          }
        });
      return null
    }));

    fechaFactura = moment(comprobante.Fecha)
    codigoFactura = (comprobante.Serie ? comprobante.Serie : '') + ' - ' + (comprobante.Folio ? comprobante.Folio : '')
    uuid = timbre.UUID
    tipoDeCambio = comprobante.TipoCambio || 1

    if (insumosSinRegistrar > 0) {
      notification.warning({
        message: insumosSinRegistrar + ' insumos no estan registrados',
      })
      return this.props.history.push("/panel/leerXml", { fileName: state.fileName, fileData: JSON.stringify(hola), origin: '/panel/agregarFacturaSinOrdenDeCompra' })
    }
    if (proveedorId) {
      this.onChangeProveedor(proveedorId)
    }
    if (uuid) {
      this.onChangeUUID(uuid)
    }


    const moneda = this.state.todasMonedas?.find(
      (valueTodos) =>
        valueTodos.abreviacion?.toLowerCase() === comprobante.Moneda?.toLowerCase()
    );

    if (moneda) {
      monedaId = moneda._id
    } else {
      message.info('No encontramos la moneda del comprobante')
      monedaId = '60c7e4396465ad440c41c15a'
    }

    await this.formRef.current.setFieldsValue({ tipoDeCambio, monedaId, insumos, proveedorId, fechaFactura, codigoFactura, uuid })
    insumos.map((value, index) => this.onChangePrecioCantidadDescuento(null, index))
    this.setState({
      archivosXml: [
        {
          name: state.fileName,
          status: "success",
        },
      ],
      archivo: state.fileData,
      loading: false
    });
  }

  onChangeUUID(value) {
    axios
      .post(
        "facturas/checarUUID",
        { uuid: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.existeUUID === 1) {
          const key = `open${Date.now()}`
          notification.warning({
            message: 'Este uuid ya existe en una factura previa',
            description: '¿Quieres continuar?',
            key: key,
            btn: (
              <Space>
                <ButtonItem style={{ marginRight: 10 }} type='primary' onClick={() => notification.close(key)}>Si</ButtonItem>
                <ButtonItem style={{ marginRight: 10 }} type='primary' onClick={() => {
                  this.props.history.goBack()
                  notification.close(key)
                }} danger>No</ButtonItem>
              </Space>
            )
          })
        } else if (res.data.existeUUID === 0) {
        }
      });
  }

  abrirAgregarMarbetes(value, marbetes) {
    this.setState({
      modalAgregarMarbetes: true,
      modalAgregarMarbetesIndex: value,
      modalAgregarMarbetesMarbetes: marbetes
    })
  }

  cerrarAgregarMarbetes(value) {
    const insumos = this.formRef.current.getFieldValue('insumos')
    insumos[this.state.modalAgregarMarbetesIndex].marbetes = value
    this.formRef.current.setFieldsValue({
      insumos: insumos
    })
    this.setState({
      modalAgregarMarbetes: false,
      modalAgregarMarbetesIndex: null,
      modalAgregarMarbetesMarbetes: null
    })
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Factura sin Orden de Compra"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Space>
              {this.props.permisoAccesoLeerXml &&
                <Upload
                  showUploadList={{
                    showRemoveIcon: false,
                    showPreviewIcon: false,
                  }}
                  fileList={this.state.archivosXml}
                  customRequest={this.leerXml.bind(this)}
                  accept=".xml"
                >
                  <ButtonItem type="primary" icon={<UploadOutlined />}>Leer Xml</ButtonItem>
                </Upload>
              }
              <ButtonItem blanco onClick={this.actualizarBoton.bind(this)}>Actualizar Informacion</ButtonItem>
            </Space>
          }
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarFacturaSinOrdenDeCompra"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Recibir
                </ButtonItem>
              }
              onFinish={(values) => this.setState({ modalConfirmacion: true, valuesForm: values })}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem label="Proyecto" name="proyectoId" margin required>
                          <SelectItem placeholder="Proyecto">
                            {this.state.objetoProyectos}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proyectoId = getFieldValue("proyectoId");
                            if (!proyectoId)
                              return <p>Seleccione un proyecto primero</p>;
                            let proyecto = this.state.todasProyectos.find(
                              (value) => value._id === proyectoId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre: {proyecto.nombre}
                                    </h1>
                                    <p>Descripcion: {proyecto.descripcion}</p>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                                    {proyecto.calle ? (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Calle: {proyecto.calle}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Numero: {proyecto.numeroCalle}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Marca: {proyecto.marca}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Modelo: {proyecto.modelo}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Placas: {proyecto.placas}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Proveedor"
                          name="proveedorId"
                          margin
                          required
                        >
                          <SelectItem placeholder="Proveedor" onChange={this.onChangeProveedor.bind(this)}>
                            {this.state.objetoProveedores}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proveedorId = getFieldValue("proveedorId");
                            if (!proveedorId)
                              return <p>Seleccione un proveedor primero</p>;
                            let proveedor = this.state.todasProveedores.find(
                              (value) => value._id === proveedorId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre:{" "}
                                      {proveedor.nombre
                                        ? proveedor.nombre +
                                        " " +
                                        proveedor.apellidoPaterno +
                                        " " +
                                        proveedor.apellidoMaterno
                                        : proveedor.razonSocial}
                                    </h1>
                                    <p style={{ margin: 0 }}>Rfc: {proveedor.rfc}</p>
                                    <p style={{ margin: 0 }}>
                                      Correo: {proveedor.correo}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Telefono: {proveedor.telefono}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Celular: {proveedor.celular}
                                    </p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ margin: 0 }}>
                                      Contacto: {proveedor.nombreContacto}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Tel. Contacto: {proveedor.telefonoContacto}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Correo Contacto: {proveedor.correoContacto}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Dias de crédito:{" "}
                                      {proveedor.diasCredito}
                                    </p>
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                  </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                    <FormItem
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.insumos?.length !==
                        curr.insumos?.length
                      }
                    >
                      {({ getFieldValue }) => {
                        const insumos = getFieldValue("insumos");
                        const disabled =
                          insumos && insumos.length > 0
                            ? true
                            : false;
                        return (
                          <FormItem
                            required
                            margin
                            name="monedaId"
                            label="Moneda"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue='60c7e4396465ad440c41c15a'
                          >
                            <SelectItem disabled={disabled} onChange={(value) => {
                              if (value === '60c7e4396465ad440c41c15a') {
                                this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                              }
                            }} placeholder="Moneda">
                              {this.state.objetoMonedas}
                            </SelectItem>
                          </FormItem>
                        )
                      }}
                    </FormItem>
                    <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.insumos?.length !== curr.insumos?.length}>
                      {({ getFieldValue }) => {

                        const monedaId = getFieldValue('monedaId')
                        const insumos = getFieldValue("insumos");
                        const disabled =
                          insumos && insumos.length > 0
                            ? true
                            : false;

                        return (
                          <FormItem
                            required
                            number
                            margin
                            label="T. Cambio"
                            name="tipoDeCambio"
                            type="number"
                            initialValue={1}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <InputNumberItem
                              disabled={monedaId === '60c7e4396465ad440c41c15a' || disabled}
                              dinero
                              placeholder="T. Cambio"
                            />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>DATOS FACTURA</HeaderItem>
              <BoxItem>
                <Row>
                  <Col span={12}>
                    <FormItem
                      name="codigoFactura"
                      label="Codigo Factura"
                      margin
                      pattern
                    >
                      <InputItem placeholder="Codigo Factura" />
                    </FormItem>
                    <FormItem
                      name="descripcion"
                      label="Descripcion"
                      margin
                      pattern
                    >
                      <InputItem placeholder="Descripcion" />
                    </FormItem>
                    <FormItem name="uuid" label="UUID" margin pattern required>
                      <InputItem onChange={(e) => this.onChangeUUID(e.target.value)} placeholder="UUID" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Fecha de Factura"
                      name="fechaFactura"
                      required
                      margin
                    >
                      <DatePickerItem
                        placeholder="Fecha de Factura"
                        allowClear={false}
                        onChange={(value) => {
                          const fechaFactura = moment(value)
                          const diasCredito = this.formRef.current.getFieldValue('diasCredito')
                          this.formRef.current.setFieldsValue({ fechaPago: fechaFactura.add(diasCredito, 'days') })
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Dias de credito"
                      name="diasCredito"
                      required
                      numberCero
                      margin
                    >
                      <InputNumberItem placeholder="Dias de Credito" onChange={(value) => {
                        const fechaFactura = this.formRef.current.getFieldValue('fechaFactura')
                        this.formRef.current.setFieldsValue({ fechaPago: moment(fechaFactura).add(value, 'days') })
                      }} />
                    </FormItem>
                    <FormItem
                      label="Fecha probable Pago"
                      name="fechaPago"
                      required
                      margin
                    >
                      <DatePickerItem
                        placeholder="Fecha probable Pago"
                        allowClear={false}
                        onChange={(value) => {
                          const fechaFactura = moment(this.formRef.current.getFieldValue('fechaFactura'))
                          let fecha = value
                          let diferencia = fecha.diff(fechaFactura, 'days') + 1
                          this.formRef.current.setFieldsValue({ diasCredito: diferencia })
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </BoxItem>
              <HeaderItem>INSUMOS</HeaderItem>
              <BoxItem>
                <Row>
                  <Col span={8}>

                    <FormItem
                      name="recibirInsumos"
                      label="Recibir Insumos"
                      margin
                      valuePropName="checked"
                      //Quitar true
                      initialValue={true}
                    >
                      <Checkbox></Checkbox>
                    </FormItem>
                  </Col>
                </Row>
                <FormInitial.List name="insumos">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th className="th-border" style={{ width: "20%" }}>
                                Insumo
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Unitario
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Neto
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Cantidad
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Descuento
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Subtotal
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Impuestos
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Total
                              </th>
                              <th
                                className="th-border-sin-right"
                                style={{ width: "14%" }}
                              >
                                Accion
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody key={arrayKey}>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  fieldKey={[arrayKey, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    sinBorde
                                    autoFocus
                                    style={{ width: "100%" }}
                                    placeholder="Insumo"
                                    onChange={(value) =>
                                      this.onChangeInsumo(value, arrayKey)
                                    }
                                  >
                                    {this.state.objetoInsumos}
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "precioUnitario"]}
                                  fieldKey={[arrayKey, "precioUnitario"]}
                                  noStyle
                                  required
                                  numberCero
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Precio Unitario"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                    dinero
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  noStyle
                                  shouldUpdate
                                >
                                  {({ getFieldValue }) => {
                                    let arrayValue = getFieldValue("insumos");
                                    let disabled = true
                                    if (arrayValue[arrayKey]?.insumoId) {
                                      const insumo = this.state.todasInsumos.find(
                                        (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                      );
                                      disabled = !!insumo.impuestos[0]?.combustible
                                    }
                                    return (
                                      <FormItem
                                        name={[name, "precioUnitarioConImpuestos"]}
                                        noStyle
                                        required
                                        numberCero
                                        shouldUpdate
                                      >
                                        <InputNumberItem
                                          sinBorde
                                          disabled={disabled}
                                          placeholder="Precio Unitario con Impuestos"
                                          onChange={(value) =>
                                            this.onChangePrecioImpuestos(value, arrayKey)
                                          }
                                          dinero={!disabled}
                                          dineroDisabled={disabled}
                                        />
                                      </FormItem>
                                    )
                                  }}
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidad"]}
                                  fieldKey={[arrayKey, "cantidad"]}
                                  required
                                  number
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Cantidad"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "descuento"]}
                                  fieldKey={[arrayKey, "descuento"]}
                                  required
                                  numberCero
                                  noStyle
                                  shouldUpdate
                                  initialValue={0}
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Descuento"
                                    porcentaje
                                    max={100}
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "subtotal"]}
                                  fieldKey={[arrayKey, "subtotal"]}
                                  required
                                  numberCero
                                  noStyle
                                  shouldUpdate
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Subtotal"
                                    dineroDisabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  noStyle
                                  shouldUpdate
                                >
                                  {({ getFieldValue }) => {
                                    let arrayValue = getFieldValue("insumos");
                                    let disabled = true
                                    if (arrayValue[arrayKey]?.insumoId) {
                                      const insumo = this.state.todasInsumos.find(
                                        (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                      );
                                      disabled = !insumo.impuestos[0]?.combustible
                                    }
                                    return (
                                      <FormItem
                                        name={[name, "impuestos"]}
                                        required
                                        numberCero
                                        noStyle
                                      >
                                        <InputNumberItem
                                          sinBorde
                                          dinero={!disabled}
                                          dineroDisabled={disabled}
                                          disabled={disabled}
                                          placeholder="Impuestos"
                                          onChange={(value) =>
                                            this.onChangePrecioCantidadDescuento(value, arrayKey)
                                          }
                                        />
                                      </FormItem>
                                    )
                                  }}
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "total"]}
                                  fieldKey={[arrayKey, "total"]}
                                  required
                                  numberCero
                                  noStyle
                                  shouldUpdate
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    placeholder="Total"
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border-sin-right">
                                <Row>
                                  <Col span={8} style={{ textAlign: 'center' }}>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Col>
                                  <Col span={16}>
                                    <FormItem
                                      noStyle
                                      shouldUpdate
                                    >
                                      {({ getFieldValue }) => {
                                        let arrayValue = getFieldValue("insumos");
                                        let mostrarMarbetes = false
                                        if (arrayValue[arrayKey]?.insumoId) {
                                          const insumo = this.state.todasInsumos.find(
                                            (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                          );
                                          mostrarMarbetes = insumo.marbetes
                                        }
                                        if (mostrarMarbetes) {
                                          return (
                                            <ButtonItem
                                              type="primary"
                                              size='small'
                                              block
                                              onClick={() => {
                                                this.abrirAgregarMarbetes(arrayKey, arrayValue[arrayKey].marbetes)
                                              }}
                                            >
                                              Marbetes
                                            </ButtonItem>
                                          )
                                        }
                                      }}
                                    </FormItem>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <FormItem noStyle>
                        <ButtonItem
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            justifySelf: fields.length > 0 ? "left" : null,
                            width: fields.length > 0 ? "auto" : null,
                          }}
                          type="primary"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Agregar Insumo
                        </ButtonItem>
                      </FormItem>
                    </>
                  )}
                </FormInitial.List>
                <Row>
                  <Col span={14}></Col>
                  <Col span={10}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        let arrayInsumos = getFieldValue("insumos") || [];
                        let ajusteSubtotal = getFieldValue('ajusteSubtotal') || 0
                        let arrayRetenciones = [];
                        let cuentasProveedor = 0;
                        let subtotal = 0;
                        let subtotalVI = 0
                        let arrayImpuestos = [];
                        let total = 0;
                        arrayInsumos.map((value) => {
                          if (value) {
                            if (
                              value.subtotal &&
                              value.total &&
                              value.arrayImpuestos
                            ) {
                              subtotal += value.subtotal;
                              subtotalVI += value.subtotal;
                              total += value.subtotal;
                              value.arrayImpuestos.map((valueImpuestos) => {
                                let index = arrayImpuestos.findIndex(
                                  (valueImpuestosAnterios) =>
                                    valueImpuestosAnterios.descripcionFactura ===
                                    valueImpuestos.descripcionFactura
                                );
                                if (index !== -1) {
                                  let newItem = {
                                    totalImpuestos:
                                      arrayImpuestos[index].totalImpuestos +
                                      valueImpuestos.totalImpuestos,
                                    descripcionFactura:
                                      valueImpuestos.descripcionFactura,
                                  };
                                  arrayImpuestos.splice(index, 1, newItem);
                                } else {
                                  arrayImpuestos.push({
                                    totalImpuestos: valueImpuestos.totalImpuestos,
                                    descripcionFactura:
                                      valueImpuestos.descripcionFactura,
                                  });
                                }
                                return null;
                              });
                              if (value.arrayRetenciones) {
                                value.arrayRetenciones.map((valueRetenciones) => {
                                  let index = arrayRetenciones.findIndex(
                                    (valueRetencionesAnterios) =>
                                      valueRetencionesAnterios.descripcionFactura ===
                                      valueRetenciones.descripcionFactura
                                  );
                                  cuentasProveedor += round2Decimals(valueRetenciones.totalRetenciones);
                                  if (index !== -1) {
                                    let newItem = {
                                      totalRetenciones:
                                        arrayRetenciones[index].totalRetenciones +
                                        valueRetenciones.totalRetenciones,
                                      descripcionFactura:
                                        valueRetenciones.descripcionFactura,
                                    };
                                    arrayRetenciones.splice(index, 1, newItem);
                                  } else {
                                    arrayRetenciones.push({
                                      totalRetenciones: valueRetenciones.totalRetenciones,
                                      descripcionFactura:
                                        valueRetenciones.descripcionFactura,
                                    });
                                  }
                                  return null;
                                });
                              }
                            }
                          }
                          return null;
                        });
                        subtotal += ajusteSubtotal
                        total += ajusteSubtotal
                        arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
                          let ajusteImpuesto = getFieldValue('ajuste' + valueImpuesto.descripcionFactura) || 0
                          total += round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto)
                          return {
                            ...valueImpuesto,
                            totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos + ajusteImpuesto),
                            totalImpuestosVI: valueImpuesto.totalImpuestos
                          }
                        })
                        arrayRetenciones = arrayRetenciones.map(valueRetencion => {
                          let ajusteRetencion = getFieldValue('ajuste' + valueRetencion.descripcionFactura) || 0
                          cuentasProveedor += ajusteRetencion
                          return {
                            ...valueRetencion,
                            totalRetenciones: (valueRetencion.totalRetenciones + ajusteRetencion),
                            totalRetencionesVI: valueRetencion.totalRetenciones
                          }
                        })
                        return (
                          <table className="table">
                            <tbody>
                              <tr style={{ marginBottom: 10 }}>
                                <th
                                  className="td-totales-center"
                                  style={{ width: "50%" }}
                                >
                                </th>
                                <td
                                  className="td-totales-center"
                                  style={{ width: "20%" }}
                                >
                                  Ajuste
                                </td>
                                <td
                                  className="td-totales-center"
                                  style={{ width: "30%" }}
                                >
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className="th-totales"
                                  style={{ width: "50%" }}
                                >
                                  Subtotal
                                </th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                  <FormItem
                                    name='ajusteSubtotal'
                                    numberNegativo
                                    noStyle
                                    max={this.state.ajusteMax}
                                    min={Math.max(-this.state.ajusteMax, -subtotalVI)}
                                    type="number"
                                    required
                                    initialValue={0}
                                  >
                                    <InputNumberItem
                                      step={.01}
                                      sinBorde
                                      dinero
                                      precision={2}
                                      placeholder={"Max: ±" + this.state.ajusteMax}
                                    />
                                  </FormItem>
                                </td>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "30%" }}
                                >
                                  {dineroDisabledMask(subtotal)}
                                </td>
                              </tr>
                            </tbody>
                            {arrayImpuestos.map((valueArray) => (
                              <tbody>
                                <tr key={valueArray.descripcionFactura}>
                                  <th className="th-totales">
                                    {valueArray.descripcionFactura}
                                  </th>
                                  <td
                                    className="td-totales-right"
                                    style={{ width: "20%" }}
                                  >
                                    <FormItem
                                      name={'ajuste' + valueArray.descripcionFactura}
                                      numberNegativo
                                      noStyle
                                      max={this.state.ajusteMax}
                                      min={Math.max(-this.state.ajusteMax, -valueArray.totalImpuestosVI)}
                                      type="number"
                                      required
                                      initialValue={0}
                                      precision={2}
                                    >
                                      <InputNumberItem
                                        step={.01}
                                        sinBorde
                                        dinero
                                        placeholder={"Max: ±" + this.state.ajusteMax}
                                      />
                                    </FormItem>
                                  </td>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(
                                      valueArray.totalImpuestos
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total</th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                ></td>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(total)}
                                </td>
                              </tr>
                            </tbody>
                            {arrayRetenciones.map((value) => (
                              <tbody>
                                <tr>
                                  <th className="th-totales">
                                    {value.descripcionFactura}
                                  </th>
                                  <td
                                    className="td-totales-right"
                                    style={{ width: "20%" }}
                                  >
                                    <FormItem
                                      name={'ajuste' + value.descripcionFactura}
                                      numberNegativo
                                      noStyle
                                      max={this.state.ajusteMax}
                                      min={Math.max(-this.state.ajusteMax, -value.totalRetencionesVI)}
                                      type="number"
                                      required
                                      initialValue={0}
                                      precision={2}
                                    >
                                      <InputNumberItem
                                        step={.01}
                                        sinBorde
                                        dinero
                                        placeholder={"Max: ±" + this.state.ajusteMax}
                                      />
                                    </FormItem>
                                  </td>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(value.totalRetenciones)}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total a pagar</th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                ></td>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(total - cuentasProveedor)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }}
                    </FormItem>
                  </Col>
                </Row>
              </BoxItem>
            </Form>
          </Spin>
        </div>
        <ModalItem
          title="Confirmacion"
          visible={this.state.modalConfirmacion}
          onCancel={() => this.setState({ modalConfirmacion: false })}
          onOk={() => {
            this.setState({ modalConfirmacion: false });
            this.onFinish(this.state.valuesForm);
          }}
          spinning={false}
          footer
        >
          <h1 style={{ textAlign: 'center' }}>Desea continuar</h1>
        </ModalItem>
        <ElegirTipoDeProveedor
          visible={this.state.modalElegirTipoDeProveedor}
          id={this.state.modalElegirTipoDeProveedorId}
          nombre={this.state.modalElegirTipoDeProveedorNombre}
          cerrarElegirTipoDeProveedor={this.cerrarElegirTipoDeProveedor.bind(this)}
        />
        <AgregarMarbetes
          visible={this.state.modalAgregarMarbetes}
          cerrarAgregarMarbetes={this.cerrarAgregarMarbetes.bind(this)}
          marbetes={this.state.modalAgregarMarbetesMarbetes}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisoAccesoLeerXml:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "647a1df7bd6a695a9801702f"
      )?.activado,
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarFactura);

import React, { Component } from 'react';
import Input from 'antd/es/input';
import './Styles.css'; // Importa el archivo CSS

class InputItem extends Component {
    render() {
        const { style, sinBorde, width, ...rest } = this.props;

        // Definir la clase CSS que se aplicará
        const className = `input-item ${sinBorde ? 'sin-borde' : ''}`;

        return (
            <Input 
                {...rest} 
                className={sinBorde ? undefined : className} 
                style={{ width: width || '100%', ...style}} 
                bordered={false} 
            />
        );
    }
}

export default InputItem;


/*import React, {Component} from "react";
import {Input} from 'antd'

class InputItem extends Component{
    render(){
        const style = {
            ...this.props.style,
            borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
            borderRadius: 0,
            width: this.props.width ? this.props.width : '100%',
        }
        return(
            <Input {...this.props} style={style} bordered={false}/>
        )
    }
}

export default InputItem*/
import { message, Space, Form as FormInitial, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import facturaPdf from "../../../../Pdf/facturaPdf";
import remisionPdf from "../../../../Pdf/remisionPdf";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MandarCorreo extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "ventas/datosPdf",
        { ventaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if(res.data?.correosEnvio?.length > 0){
          this.formRef.current.setFieldsValue({correosEnvio: res.data.correosEnvio})
        }
        const pdf = res.data.factura ? pdfMake.createPdf(facturaPdf(res.data)) : pdfMake.createPdf(remisionPdf(res.data));
        this.setState(
          {
            loading: false,
            pdf: pdf,
            factura: res.data.factura
          },
          () => {
            pdf.open({}, window.frames["ventaPdf"]);
          }
        );
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    if(values?.correosEnvio?.length > 0){
    this.setState({ loading: true });
    axios
      .post(
        "ventas/enviarVenta",
        { ...values, ventaId: this.props.id, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(this.state.factura ? "Factura enviada" : 'Remision enviada');
          this.formRef.current.resetFields();
          this.cerrarMandarCorreo();
        } else if (res.data.success === 1) {
          message.error(this.state.factura ? "No se pudo enviar la factura a ese correo" : "No se pudo enviar la remision a ese correo");
        } else {
          message.error("Sucedio un error y no se pudo enviar");
        }
      });
    }else{
      message.info('Proporcione al menos un correo de envio')
    }
  }
  cerrarMandarCorreo() {
    this.props.cerrarMandarCorreo();
    this.formRef.current.resetFields()
  }
  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarMandarCorreo.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="mandarCorreo"
          layout
          width={700}
          onCancel={this.cerrarMandarCorreo.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
        <FormInitial.List
          name="correosEnvio"
          initialValue={[{correo: ''}]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({name}, arrayKey) => (
                <Row>
                  <Col span={22}>
                  <FormItem
                    name={[name, "correo"]}
                    fieldKey={[arrayKey, "correo"]}
                    label='Correo'
                    required
                    margin
                    email
                    pattern
                  >
                    <InputItem style={{width: '100%'}} placeholder="Correo" />
                  </FormItem>
                  </Col>
                  <Col span={2}>
                    <div style={{textAlign: 'center'}}>
                      <MinusCircleOutlined
                          onClick={() => remove(name)}
                        />
                    </div>
                  </Col>
                </Row>
              ))}
              {fields.length <5 ?
              <div style={{textAlign: 'center', marginTop: '5px'}}>
                <ButtonItem
                  type="primary"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Agregar
                </ButtonItem>
                </div>
              : null}
            </>
          )}
        </FormInitial.List>
          <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
            <Space>
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Mandar Correo
            </ButtonItem>
              <ButtonItem
              type="primary"
              danger
              onClick={this.cerrarMandarCorreo.bind(this)}
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Cancelar
            </ButtonItem>
            </Space>
          </div>
        </Form>
        <iframe
          title={"venta"}
          style={{ width: "100%", height: "100vh" }}
          id="ventaPdf"
          name="ventaPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(MandarCorreo);

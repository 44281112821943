import {  message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, ModalItem, FormItem, SelectItem, InputItem, ButtonItem } from "../../../../../Components/Items";
import TextAreItem from "../../../../../Components/TextAreaItem";

const {Option} = Select

class AgregarUnidad extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoUnidadesSat: null,
    todasUnidadesSat: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({loading: true})
    await axios
    .post(
      "unidadesSat/selectUnidades",
      { empresaId: this.props.empresaId },
      { headers: this.props.headersToken }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        let dataSorted = res.data.sort((a,b) => {
          if(!a.comun && b.comun){
            return 1
          }else if(a.comun && !b.comun){
            return -1
          }else{
            return 0
          }
        })
        this.setState({ todasUnidadesSat: dataSorted });
        this.setState({
          objetoUnidadesSat: dataSorted.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.c_ClaveUnidad +
                  " - " +
                  value.nombre}
              </Option>
            );
          }),
        });
      }
    });
    this.setState({loading: false})
  }
  cerrarAgregarUnidad(codigo) {
    this.props.cerrarAgregarUnidad(codigo);
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post("unidades/agregarUnidad", {...values, empresaId: this.props.empresaId}, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.id) {
          message.success("Se agrego una unidad");
          this.formRef.current.resetFields();
          this.cerrarAgregarUnidad(res.data.id);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  render() {
    return (
      <ModalItem
        title="Agregar Unidad"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarAgregarUnidad.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="agregarUnidad"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }} 
            >
              Agregar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="nombre"
            label="Nombre"
            required
            max={100}
            margin
            pattern
          >
            <InputItem placeholder="Nombre" />
          </FormItem>
          <FormItem name="descripcion" label="Descripción" margin pattern>
            <TextAreItem placeholder="Descripción" />
          </FormItem>
          <FormItem label="Unidad Sat" name="unidadSatId" required margin>
            <SelectItem placeholder="Unidad Sat">
              {this.state.objetoUnidadesSat}
            </SelectItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id
  };
};

export default connect(mapStateToProps)(AgregarUnidad);

import { PageHeader, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import AgregarUnidadDeNegocio from "./AgregarUnidadDeNegocio/AgregarUnidadDeNegocio";
import { TableItem, cuentaMask, ButtonItem } from "../../../Components/Items";
import { EditOutlined } from "@ant-design/icons";
import EditarUnidadDeNegocio from "./EditarUnidadDeNegocio/EditarUnidadDeNegocio";

class UnidadesDeNegocio extends Component {
  state = {
    todasUnidadesDeNegocio: [],
    modalAgregarUnidadDeNegocio: false,
    modalEditarUnidadDeNegocio: false,
    modalEditarUnidadDeNegocioId: null,
    loading: true
  };
  componentDidMount() {
    this.consultarUnidadesDeNegocio();
  }

  consultarUnidadesDeNegocio() {
    this.setState({loading: true})
    axios
    .post(
      "unidadesdenegocio/selectTodasUnidadesDeNegocio",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasUnidadesDeNegocio: res.data.map(value => {
            return {...value, codigo: cuentaMask(
              value.nivel1 +
                value.nivel2 +
                value.nivel3 +
                value.nivel4 +
                value.nivel5
            ), accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }

  abrirAgregarUnidadDeNegocio() {
    this.setState({ modalAgregarUnidadDeNegocio: true });
  }
  cerrarAgregarUnidadDeNegocio() {
    this.setState({ modalAgregarUnidadDeNegocio: false });
    this.consultarUnidadesDeNegocio();
  }
  abrirEditarUnidadDeNegocio(id) {
    this.setState({ modalEditarUnidadDeNegocio: true, modalEditarUnidadDeNegocioId: id });
  }
  cerrarEditarUnidadDeNegocio() {
    this.setState({ modalEditarUnidadDeNegocio: false });
    this.consultarUnidadesDeNegocio();
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarUnidadDeNegocio &&
              <EditOutlined
                onClick={() => this.abrirEditarUnidadDeNegocio(value._id)}
                style={{ color: "blue" }}
              />
            }
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title="Unidades De Negocio"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarUnidadDeNegocio &&
            <ButtonItem
              type="primary"
              key="agregarUnidadDeNegocio"
              onClick={this.abrirAgregarUnidadDeNegocio.bind(this)}
            >
              Agregar Unidad de Negocio
            </ButtonItem>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasUnidadesDeNegocio} columns={columns} />
        <AgregarUnidadDeNegocio
          visible={this.state.modalAgregarUnidadDeNegocio}
          cerrarAgregarUnidadDeNegocio={this.cerrarAgregarUnidadDeNegocio.bind(
            this
          )}
        />
                                <EditarUnidadDeNegocio
          visible={this.state.modalEditarUnidadDeNegocio}
          id={this.state.modalEditarUnidadDeNegocioId}
          cerrarEditarUnidadDeNegocio={this.cerrarEditarUnidadDeNegocio.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarUnidadDeNegocio: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ced01f30e81b54183cfc7")?.activado,
    permisoEditarUnidadDeNegocio: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "616086adda9f3257f9e7efbb")?.activado,
  };
};

export default connect(mapStateToProps)(UnidadesDeNegocio);

import {  Checkbox, message, Select, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  HeaderItem,
  BoxItem,
  ButtonItem,
} from "../../../../Components/Items";

const { Option } = Select;

class ElegirTipoDeCliente extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoTipoDeCliente: null,
    todasTipoDeCliente: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarElegirTipoDeCliente(elegido) {
    this.props.cerrarElegirTipoDeCliente(elegido);
  }
  async consultarTodo() {
    await axios
      .post(
        "tiposdeclientes/selectTiposDeClientes",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasTipoDeCliente: res.data });
          this.setState({
            objetoTipoDeCliente: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "clientes/elegirTipoDeCliente",
        {
          ...values,
          empresaId: this.props.empresaId,
          clienteId: this.props.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se edito el cliente");
          this.formRef.current.resetFields();
          this.cerrarElegirTipoDeCliente(true);
        } else if (res.data.success === 1) {
          message.error("No se puede editar el cliente");
        } else {
          message.error("Hubo un error y el cliente no se edito");
        }
      });
  }
  onChangeTipoDeCliente(value) {
    const tipoDeCliente = this.state.todasTipoDeCliente.find(
      (valueTodos) => valueTodos._id.toString() === value.toString()
    );
    if (tipoDeCliente.generarCuentasAutomatico) {
      this.formRef.current.setFieldsValue({
        generarCuentaAnticipoId: false,
        generarCuentaFonGarId: false,
        generarCuentaDeudorId: false,
      });
    } else {
      this.formRef.current.setFieldsValue({
        generarCuentaAnticipoId: false,
        generarCuentaFonGarId: false,
        generarCuentaDeudorId: false,
      });
    }
  }
  render() {
    return (
      <>
        <ModalItem
          title={this.props.nombre}
          visible={this.props.visible}
          spinning={this.state.loading}
          paddingTop
          onCancel={() => this.cerrarElegirTipoDeCliente(false)}
        >
          <Form
            ref={this.formRef}
            name="elegirTipoDeCliente"
            layout
            bottom={
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Ok
              </ButtonItem>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <Row>
              <Col span={24}>
                <HeaderItem>SELECCIONAR UN TIPO DE CLIENTE</HeaderItem>
              </Col>
            </Row>
            <FormItem
              label="Tipo de Cliente"
              margin
              name="tipoClienteId"
              required
            >
              <SelectItem
                placeholder="Tipo de Cliente"
                onChange={this.onChangeTipoDeCliente.bind(this)}
              >
                {this.state.objetoTipoDeCliente}
              </SelectItem>
            </FormItem>
            <FormItem shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const tipoDeClienteId = getFieldValue("tipoClienteId");
                const tipoDeCliente = tipoDeClienteId
                  ? this.state.todasTipoDeCliente.find(
                      (valueTodos) =>
                        valueTodos._id.toString() === tipoDeClienteId.toString()
                    )
                  : null;
                const disabled = tipoDeCliente?.generarCuentasAutomatico
                  ? false
                  : true;
                return (
                  <>
                    <Row>
                      <Col span={24}>
                        <HeaderItem>CUENTAS CONTABLES</HeaderItem>
                      </Col>
                    </Row>
                    <BoxItem>
                      <FormItem
                        label="Anticipo"
                        margin
                        name="generarCuentaAnticipoId"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox disabled={disabled}></Checkbox>
                      </FormItem>
                      <FormItem
                        label="Fondo de Garantía"
                        margin
                        name="generarCuentaFonGarId"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox disabled={disabled}></Checkbox>
                      </FormItem>
                      <FormItem
                        label="Deudor"
                        margin
                        name="generarCuentaDeudorId"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox disabled={disabled}></Checkbox>
                      </FormItem>
                    </BoxItem>
                  </>
                );
              }}
            </FormItem>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ElegirTipoDeCliente);

import React, { Component } from 'react';
import { Switch } from 'antd';

class SwitchItem extends Component {
  render() {
    return <Switch {...this.props}   />;
  }
}


export default SwitchItem;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, PageHeader, Row, Spin, Form as FormInitial, message, Select } from 'antd'
import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  NotificacionPolizas,
  DatePickerItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import VistaPrevia from '../VistaPrevia/VistaPrevia';
import AgregarMarbetes from '../../Marbetes/AgregarMarbetes/AgregarMarbetes';
import ElegirMarbetes from '../../Marbetes/ElegirMarbetes/ElegirMarbetes';

const { Option } = Select

class AgregarOrdenDeProduccion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    todasInsumos: null,
    objetoInsumos: null,
    modalVistaPrevia: false,
    modalVistaPreviaId: null,
    modalAgregarMarbetes: false,
    modalAgregarMarbetesMarbetes: [],
    modalElegirMarbetes: false,
    modalElegirMarbetesMarbetes: [],
    modalElegirMarbetesInsumoId: null,
    modalElegirMarbetesProyectoId: null
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "insumos/selectInsumosInventariables",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1] || e.errorFields[index].name[1] === 0) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "produccion/agregarOrdenDeProduccion",
        {
          ...values,
          empresaId: this.props.empresaId,
          marbetes: this.state.modalAgregarMarbetesMarbetes
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se agrego una orden de producción"
          );
          NotificacionPolizas(res.data.polizasId)
          this.setState({
            modalVistaPrevia: true,
            modalVistaPreviaId: res.data.id,
          });
        } else if (res.data.success === 1) {
          message.error(res.data.message ? res.data.message : "No se pudo producir el insumo");
        } else {
          message.error("Hubo un error y el insumo no se produjo");
        }
      });
  }
  async onChangeInsumo(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumosUtilizados");
    let proyecto = await this.formRef.current.getFieldValue("proyectoId");
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
    if (!insumo) return
    await axios
      .post(
        "inventarios/inventarioInsumoProyecto",
        {
          proyectoId: proyecto,
          insumoId: insumoId,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        arrayValue[key].cantidadProyecto = res.data.cantidad;
      });
    this.formRef.current.setFieldsValue({
      insumosUtilizados: arrayValue,
    });
  }
  cerrarVistaPrevia() {
    this.setState({
      modalVistaPrevia: false,
      modalVistaPreviaId: null,
    });
    this.formRef.current.resetFields();
    this.atras();
  }
  abrirAgregarMarbetes(marbetes) {
    this.setState({
      modalAgregarMarbetes: true,
      modalAgregarMarbetesMarbetes: marbetes
    })
  }

  cerrarAgregarMarbetes(value) {
    this.setState({
      modalAgregarMarbetes: false,
      modalAgregarMarbetesMarbetes: value
    })
  }

  abrirElegirMarbetes(value, marbetes, insumoId) {
    const proyectoId = this.formRef.current.getFieldValue('proyectoId')
    this.setState({
      modalElegirMarbetes: true,
      modalElegirMarbetesIndex: value,
      modalElegirMarbetesMarbetes: marbetes || [],
      modalElegirMarbetesInsumoId: insumoId,
      modalElegirMarbetesProyectoId: proyectoId
    })
  }

  cerrarElegirMarbetes(value) {
    const insumosUtilizados = this.formRef.current.getFieldValue('insumosUtilizados')
    insumosUtilizados[this.state.modalElegirMarbetesIndex].marbetes = value
    this.formRef.current.setFieldsValue({
      insumosUtilizados: insumosUtilizados
    })
    this.setState({
      modalElegirMarbetes: false,
      modalElegirMarbetesIndex: null,
      modalElegirMarbetesMarbetes: null,
      modalElegirMarbetesInsumoId: null,
      modalElegirMarbetesProyectoId: null
    })
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Orden de Producción"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarOrdenDeProduccion"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Producir
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumo a Producir"
              />
              <Row>
                <Col span={8}>
                  <FormItem label="Insumo a Generar" name="insumoGeneradoId" margin required>
                    <SelectItem placeholder="Insumo a Generar">
                      {this.state.objetoInsumos}
                    </SelectItem>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Cantidad a Generar"
                    name="cantidadGenerada"
                    number
                    required
                    type='number'
                    initialValue={1}
                  >
                    <InputNumberItem />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    noStyle
                    shouldUpdate
                  >
                    {({ getFieldValue }) => {
                      let insumoGeneradoId = getFieldValue("insumoGeneradoId");
                      let mostrarMarbetes = false
                      if (insumoGeneradoId) {
                        const insumo = this.state.todasInsumos.find(
                          (valueInsumos) => valueInsumos._id === insumoGeneradoId
                        );
                        mostrarMarbetes = insumo.marbetes
                      }
                      if (mostrarMarbetes) {
                        return (
                          <ButtonItem
                            type="primary"
                            size='small'
                            block
                            onClick={() => {
                              this.abrirAgregarMarbetes(this.state.modalAgregarMarbetesMarbetes)
                            }}
                          >
                            Marbetes
                          </ButtonItem>
                        )
                      }
                    }}
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Proyecto"
              />
              <Row>
                <Col span={12}>
                  <FormItem shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const insumosUtilizados = getFieldValue('insumosUtilizados')
                      const disabled = insumosUtilizados?.length > 0
                      return (
                        <FormItem label="Proyecto" name="proyectoId" margin required>
                          <SelectItem placeholder="Proyecto" disabled={disabled}>
                            {this.state.objetoProyectos}
                          </SelectItem>
                        </FormItem>
                      )
                    }}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Fecha de Produccion"
                    name="fechaProduccion"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Produccion"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumos a Utilizar"
              />
              <FormInitial.List name="insumosUtilizados">
                {(fields, { add, remove }) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: '50%' }}>Insumo</th>
                            <th className="th-border" style={{ width: '19%' }}>Cantidad Proyecto</th>
                            <th className="th-border" style={{ width: '19%' }}>Cantidad</th>
                            <th className="th-border" style={{ width: '10%' }}>Accion</th>
                            <th className="th-border-sin-right" style={{ width: '2%' }}></th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  fieldKey={[arrayKey, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    sinBorde
                                    autoFocus
                                    width="100%"
                                    placeholder="Insumo"
                                    onChange={(value) =>
                                      this.onChangeInsumo(
                                        value,
                                        arrayKey
                                      )
                                    }
                                  >
                                    {
                                      this.state
                                        .objetoInsumos
                                    }
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidadProyecto"]}
                                  fieldKey={[arrayKey, "cantidadProyecto"]}
                                  number
                                  noStyle
                                  required
                                  initialValue={undefined}
                                >
                                  <InputNumberItem sinBorde disabled />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  noStyle
                                  shouldUpdate={(prev, curr) =>
                                    prev.insumosUtilizados[arrayKey]?.cantidadProyecto !==
                                    curr.insumosUtilizados[arrayKey]?.cantidadProyecto
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    const insumosUtilizados = getFieldValue("insumosUtilizados");
                                    if (!insumosUtilizados[arrayKey]) return
                                    const cantidadProyecto =
                                      insumosUtilizados[arrayKey].cantidadProyecto;
                                    let max = cantidadProyecto;
                                    return (
                                      <FormItem
                                        {...restField}
                                        name={[name, "cantidad"]}
                                        fieldKey={[arrayKey, "cantidad"]}
                                        number
                                        noStyle
                                        required
                                        max={max}
                                        type='number'
                                        initialValue={undefined}
                                      >
                                        <InputNumberItem sinBorde />
                                      </FormItem>
                                    );
                                  }}
                                </FormItem>
                              </td>
                              <td className="td-border">
                              <Row>
                                  <Col span={24}>
                                    <FormItem
                                      noStyle
                                      shouldUpdate
                                    >
                                      {({ getFieldValue }) => {
                                        let arrayValue = getFieldValue("insumosUtilizados");
                                        let mostrarMarbetes = false
                                        let insumoId
                                        if (arrayValue[arrayKey]?.insumoId && this.state.todasInsumos) {
                                          const insumo = this.state.todasInsumos.find(
                                            (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                          );
                                          mostrarMarbetes = insumo.marbetes
                                          insumoId = insumo._id
                                        }
                                        if (mostrarMarbetes) {
                                          return (
                                            <ButtonItem
                                              type="primary"
                                              size='small'
                                              block
                                              onClick={() => {
                                                this.abrirElegirMarbetes(arrayKey, arrayValue[arrayKey].marbetes, insumoId)
                                              }}
                                            >
                                              Marbetes
                                            </ButtonItem>
                                          )
                                        }
                                      }}
                                    </FormItem>
                                  </Col>
                                </Row>
                              </td>
                              <td>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  style={{
                                    alignSelf: "center",
                                    justifySelf: "center",
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                    <FormItem
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.proyectoId !== curr.proyectoId
                      }
                    >
                      {({ getFieldValue }) => {
                        const proyecto = getFieldValue("proyectoId");
                        return (
                          <FormItem noStyle>
                            <ButtonItem
                              style={{ marginTop: 10, marginBottom: 10 }}
                              type="dashed"
                              onClick={
                                proyecto
                                  ? () => add()
                                  : () =>
                                    message.info(
                                      "Agregue el proyecto primero"
                                    )
                              }
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar Campo
                            </ButtonItem>
                          </FormItem>
                        );
                      }}
                    </FormItem>
                  </>
                )}
              </FormInitial.List>
            </Form>
          </Spin>
        </div>
        <VistaPrevia
          visible={this.state.modalVistaPrevia}
          id={this.state.modalVistaPreviaId}
          cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
        />
        <AgregarMarbetes
          visible={this.state.modalAgregarMarbetes}
          cerrarAgregarMarbetes={this.cerrarAgregarMarbetes.bind(this)}
          marbetes={this.state.modalAgregarMarbetesMarbetes}
        />
                <ElegirMarbetes
          visible={this.state.modalElegirMarbetes}
          cerrarElegirMarbetes={this.cerrarElegirMarbetes.bind(this)}
          marbetes={this.state.modalElegirMarbetesMarbetes}
          insumoId={this.state.modalElegirMarbetesInsumoId}
          proyectoId={this.state.modalElegirMarbetesProyectoId}
        />
      </>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarOrdenDeProduccion);
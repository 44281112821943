import { message, Row, Col, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  InputItem,
  ButtonItem,
} from "../../../../Components/Items";

const {Option} = Select

class AgregarTipoDePoliza extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
  };
  cerrarAgregarTipoDePoliza(codigo) {
    this.props.cerrarAgregarTipoDePoliza(codigo);
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "tiposdepolizas/agregarTipoDePoliza",
        { ...values, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.id) {
          message.success("Se agrego un tipo de poliza");
          this.formRef.current.resetFields();
          this.cerrarAgregarTipoDePoliza(res.data.id);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  render() {
    return (
      <ModalItem
        title="Agregar Tipo de Poliza"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarAgregarTipoDePoliza.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="agregarTipoDePoliza"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Agregar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <FormItem
                name="nombre"
                label="Nombre"
                required
                max={100}
                margin
                pattern
              >
                <InputItem placeholder="Nombre" />
              </FormItem>
              <FormItem
                name="descripcion"
                label="Descripción"
                margin
                pattern
                required
              >
                <InputItem placeholder="Descripción" />
              </FormItem>
              <FormItem
                label="Tipo de Poliza"
                margin
                name="tipoDePolizaId"
                required
              >
                <SelectItem placeholder="Tipo de Poliza">
                  <Option value={1}>Ingreso</Option>
                  <Option value={2}>Egreso</Option>
                  <Option value={3}>Traslado</Option>
                  <Option value={4}>Nomina</Option>
                  <Option value={5}>Pago</Option>
                  <Option value={6}>Diario</Option>
                </SelectItem>
              </FormItem>
            </Col>
            {/* <Col span={12}>
              <FormItem
                name="contadorPorMes"
                label="Contador Por Mes"
                specialLayout
                margin
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </FormItem>
              <FormItem
                name="usarNumeroCheque"
                label="Usar Numero de Cheque"
                specialLayout
                margin
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </FormItem>
              <FormItem
                name="automatica"
                label="Es Automatica"
                specialLayout
                margin
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </FormItem>
            </Col> */}
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarTipoDePoliza);

import { PageHeader, Checkbox } from "antd";
import React, { Component } from "react";
import AgregarTipoDeFlujo from "./AgregarTipoDeFlujo/AgregarTipoDeFlujo";
import { TableItem, ButtonItem } from "../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";

class TiposDeFlujos extends Component {
  state = {
    modalAgregarTipoDeFlujo: false,
    todasTiposDeFlujos: [],
    loading: false
  };
  componentDidMount() {
    this.consultarTiposDeFlujos();
  }

  consultarTiposDeFlujos() {
    this.setState({loading: true})
    axios
      .post(
        "tiposdeflujos/selectTodosTiposDeFlujos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({loading: false})
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasTiposDeFlujos: res.data.map((value) => {
              return {
                ...value,
                accion: { _id: value._id, estado: value.estado },
              };
            }),
          });
        }
      });
  }
  abrirAgregarTipoDeFlujo() {
    this.setState({ modalAgregarTipoDeFlujo: true });
  }
  cerrarAgregarTipoDeFlujo() {
    this.setState({ modalAgregarTipoDeFlujo: false });
    this.consultarTiposDeFlujos();
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Ingreso",
        dataIndex: "ingreso",
        key: "ingreso",
        render: (value) => <Checkbox checked={value} />,
        align: "center",
      },
      {
        title: "Considerar en Flujo",
        dataIndex: "considerarEnFlujo",
        key: "considerarEnFlujo",
        render: (value) => <Checkbox checked={value} />,
        align: "center",
      },
      {
        title: "Generar DIOT",
        dataIndex: "generarDIOT",
        key: "generarDIOT",
        render: (value) => <Checkbox checked={value} />,
        align: "center",
      },
    ];
    return (
      <>
        <PageHeader
          title="Tipos De Flujos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarTipoDeFlujo &&
            <ButtonItem
              type="primary"
              key="agregarTipoDeFlujo"
              onClick={this.abrirAgregarTipoDeFlujo.bind(this)}
            >
              Agregar Tipo De Flujo
            </ButtonItem>
          }
        />
        <TableItem
        loading={this.state.loading}
          size="small"
          dataSource={this.state.todasTiposDeFlujos}
          columns={columns}
        />
        {this.props.permisoAgregarTipoDeFlujo &&
          <AgregarTipoDeFlujo
            visible={this.state.modalAgregarTipoDeFlujo}
            cerrarAgregarTipoDeFlujo={this.cerrarAgregarTipoDeFlujo.bind(this)}
          />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarTipoDeFlujo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cdd32f30e81b54183cfa3")?.activado,
    permisoEditarTipoDeFlujo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cdd61f30e81b54183cfa4")?.activado,
    permisoDeshabilitarTipoDeFlujo: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce7d2f30e81b54183cfb7")?.activado,
  };
};

export default connect(mapStateToProps)(TiposDeFlujos);

import { message, PageHeader, Popconfirm, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem,  ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CloseCircleOutlined } from "@ant-design/icons";

moment.locale("es");

class Produccion extends Component {
  state = {
    todasOrdenesDeProduccion: [],
    loading: false,
  };
  componentDidMount() {
    this.consultarProduccion();
  }
  consultarProduccion() {
    this.setState({ loading: true });
    axios
      .post(
        "produccion/selectOrdenes",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasOrdenesDeProduccion: res.data.map(valueProd => {
              return {
                ...valueProd,
                accion: {
                  _id: valueProd._id,
                  createdAt: valueProd.createdAt,
                  estado: valueProd.estado
                }
              }
            }),
          });
        }
      });
  }
  agregarOrdenDeProduccion() {
    this.props.history.push("/panel/agregarOrdenDeProduccion");
  }
  agregarOrdenDeProduccionConLista() {
    this.props.history.push("/panel/agregarOrdenDeProduccionConLista");
  }
  cancelarProduccion(value){
    axios
    .post(
      "produccion/cancelarOrdenDeProduccion",
      { produccionId: value, empresaId: this.props.empresaId },
      { headers: this.props.headersToken }
    )
    .then((res) => {
      if (res.data.success === 2) {
        message.success("Producción cancelada");
      } else {
        message.error("Sucedio un error y no se pudo cancelar");
      }
      this.consultarProduccion();
    });
  }
  render() {
    const columns = [
      {
        title: "Numero",
        dataIndex: "numero",
        key: "numero",
        sorter: (a, b) => {
          if (a.numero < b.numero) {
            return -1;
          }
          if (a.numero > b.numero) {
            return 1;
          }
          return 0;
        },
        buscar: "numero",
        width: "20%",
      },
      {
        title: "Insumo Generado",
        dataIndex: "insumoGenerado",
        key: "insumoGenerado",
        sorter: (a, b) => {
          if (a.insumoGenerado < b.insumoGenerado) {
            return -1;
          }
          if (a.insumoGenerado > b.insumoGenerado) {
            return 1;
          }
          return 0;
        },
        buscar: "insumoGenerado",
        width: "40%",
      },
      {
        title: "Fecha",
        dataIndex: "fechaProduccion",
        key: "fechaProduccion",
        sorter: (a, b) => {
          if (moment(a.fechaProduccion) < moment(b.fechaProduccion)) {
            return -1;
          }
          if (moment(a.fechaProduccion) > moment(b.fechaProduccion)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "20%",
        ellipsis: true,
        align: "center",
        defaultSortOrder: 'descend',
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => {
          let buttons = []
          if(moment().diff(moment(value.createdAt), 'day') < 60 && value.estado !== 0){
            buttons.push(
              <Popconfirm
              title={"Seguro que quieres cancelar esta producción"}
              onConfirm={() => this.cancelarProduccion(value._id)}
              okText="Si"
              cancelText="No"
            >
              <CloseCircleOutlined style={{ color: "red" }} />
            </Popconfirm>
            )
          }
          if(value.estado === 0){
            buttons.push(
              <Tag color="red">Cancelada</Tag>
            )
          }
          return buttons
        },
        wdith: "20%",
      }
    ];

    let buttons = []

    if(this.props.permisoAgregarProduccionSinLista){
      buttons.push(
        <ButtonItem
        type="primary"
        key="agregarOrdenDeProduccion"
        onClick={this.agregarOrdenDeProduccion.bind(this)}
      >
        Agregar Orden Produccion
      </ButtonItem>
      )
    }

    if(this.props.permisoAgregarProduccionConLista){
      buttons.push(
        <ButtonItem
          blanco
          key="agregarOrdenDeProduccionConLista"
          onClick={this.agregarOrdenDeProduccionConLista.bind(this)}
        >
          Agregar Orden Con Lista
        </ButtonItem>
      )
    }

    return (
      <>
        <PageHeader
          title="Produccion"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={buttons}
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasOrdenesDeProduccion}
          columns={columns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verOrdenDeProduccion", { id: record._id });
              },
            };
          }} 
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarProduccionConLista: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6478fa3a0d24b4b09c994353")?.activado,
    permisoAgregarProduccionSinLista: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472de574b2da7c85c1a8d11")?.activado,
    permisoEditarProduccion: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472de8d4b2da7c85c1a8d12")?.activado,
  };
};

export default connect(mapStateToProps)(Produccion);

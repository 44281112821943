import React, { Component } from "react";
import { Form } from "antd";

class FormItem extends Component {
  render() {
    let rules = [];
    if (this.props.pattern) {
      rules.push({
        pattern: /^[A-Za-z0-9\n. ,"'´`#°ºªáÁéÉíÍóÓúÚüÜñÑ@_/*+%&()!-–:;]*$/,
        message: "No se admiten caracteres especiales",
      });
    } else if (this.props.rfc) {
      rules.push({
        pattern:
          /^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/,
        message: "No es un rfc valido",
      });
    } else if (this.props.number) {
      rules.push({
        pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
        message: "Solo se admiten numeros positivos",
      });
    } else if (this.props.numberNegativo) {
      rules.push({
        pattern: /^[0-9.-]*$/,
        message: "Solo se admiten numeros",
      });
    } else if (this.props.numberCero) {
      rules.push({
        pattern: /^[0-9.]*$/,
        message: "No se admiten numeros negativos.",
      });
    } else if (this.props.curp) {
      rules.push({
        pattern:
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        message: "No es un curp valido",
      });
    }
    if (this.props.required) {
      rules.push({
        required: true,
        message: "Se require este campo",
      });
    }
    if (this.props.max || this.props.max === 0) {
      rules.push({
        max: this.props.max,
        type: this.props.type,
        message: this.props.type === 'number' ? `El maximo es ${this.props.max}`: `Solo admite hasta ${this.props.max} caracteres`,
      });
    }
    if(this.props.gt || this.props.gt === 0){
      const gt = this.props.gt
      rules.push({
          validator(_, value) {
            if ( value > gt ) {
              return Promise.resolve();
            }else{
              return Promise.reject(new Error(`Tiene que ser mayor a ${gt}`));
            }
          },
          message: `Tiene que ser mayor a ${gt}`
      })
    }
    if (this.props.min || this.props.min === 0) {
      rules.push({
        min: this.props.min,
        type: this.props.type,
        message: this.props.type === 'number' ? `El minimo es ${this.props.min}`: `No se admite menos de ${this.props.min} caracteres`,
      });
    }
    if (this.props.email) {
      rules.push({
        type: "email",
        message: "No es un correo valido",
      });
    }
    let layout
    if(this.props.specialLayout){
      layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
      };
    }
    if(this.props.specialLayoutSwitch){
      layout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
      };
    }
    if(this.props.specialTableLayout){
      layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 22 },
      };
    }
    let transformedProperties = {};
    for (var key in this.props) {
      if (
        !(
          key.toString() === "children" ||
          key.toString() === "pattern" ||
          key.toString() === "required" ||
          key.toString() === "max" ||
          key.toString() === "min" ||
          key.toString() === "email" ||
          key.toString() === "margin" ||
          key.toString() === "rfc" ||
          key.toString() === "curp" ||
          key.toString() === "specialLayout" ||
          key.toString() === "type" ||
          key.toString() === "numberCero" ||
          key.toString() === "numberNegativo" ||
          key.toString() === "number"
        )
      ) {
        transformedProperties = {
          ...transformedProperties,
          [key]: this.props[key],
        };
      }
    }
    const style = {
      borderWidth: 0,
      border: 0,
      marginBottom: this.props.margin ? "3px" : this.props.marginBottom,
      ...this.props.style,
    };
    return (
      <Form.Item
        {...transformedProperties}
        rules={rules}
        style={style}
        {...layout}
      >
        {this.props.children}
      </Form.Item>
    );
  }
}

export default FormItem;

import { PageHeader, Space, Popconfirm, message, Dropdown, Menu } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem, ButtonItem } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined, DownOutlined, PlusOutlined, ImportOutlined } from '@ant-design/icons'

class Proveedores extends Component {
  state = {
    todasProveedores: [],
    loading: false
  }
  componentDidMount() {
    this.consultarProveedores();
  }

  consultarProveedores() {
    this.setState({loading: true})
    axios
    .post(
      "proveedores/selectTodosProveedores",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasProveedores: res.data
        });
      }
    });
  }
  agregarProveedor(){
    this.props.history.push('/panel/agregarProveedor')
  }
  importarProveedores(){
    this.props.history.push('/panel/importarProveedores')
  }
  editarProveedor(id){
    this.props.history.push('/panel/editarProveedor', {id})
  }
  deshabilitarProveedor(value) {
    axios
      .post(
        "proveedores/deshabilitarProveedor",
        { proveedorId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Proveedor deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarProveedores();
      });
  }
  habilitarProveedor(value) {
    axios
      .post(
        "proveedores/habilitarProveedor",
        { proveedorId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Proveedor habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarProveedores();
      });
  }
  handleMenuClick(e) {
    if(e.key === '1'){
      this.agregarProveedor()
    }
    if(e.key === '2'){
      this.importarProveedores()
    }
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        ellipsis: true,
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
        ellipsis: true,
      },
      {
        title: "Nombre Comercial",
        dataIndex: "nombreComercial",
        key: "nombreComercial",
        sorter: (a, b) => {
          if (a.nombreComercial < b.nombreComercial) {
            return -1;
          }
          if (a.nombreComercial > b.nombreComercial) {
            return 1;
          }
          return 0;
        },
        buscar: "nombreComercial",
        ellipsis: true,
      },
      {
        title: "RFC",
        dataIndex: "rfc",
        key: "rfc",
        sorter: (a, b) => {
          if (a.rfc < b.rfc) {
            return -1;
          }
          if (a.rfc > b.rfc) {
            return 1;
          }
          return 0;
        },
        buscar: "rfc",
        ellipsis: true,
      },
      {
        title: "Tipo de Proveedor",
        dataIndex: "tipoProveedor",
        key: "tipoProveedor",
        ellipsis: true,
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        ellipsis: true,
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarProveedor &&
              <EditOutlined
                onClick={() => this.editarProveedor(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarProveedor &&
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este proveedor"
                  onConfirm={() => this.deshabilitarProveedor(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este proveedor"
                  onConfirm={() => this.habilitarProveedor(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ];
    const menu = (
      <Menu onClick={this.handleMenuClick.bind(this)}>
        <Menu.Item key="1" icon={<PlusOutlined />}>
          Agregar Proveedor
        </Menu.Item>
        <Menu.Item key="2" icon={<ImportOutlined />}>
          Importar Proveedores
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <PageHeader
          title="Proveedores"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarProveedor &&
            <Dropdown overlay={menu} trigger={['click']}>
            <ButtonItem type='primary'>
          Agregar <DownOutlined />
        </ButtonItem>
          </Dropdown>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasProveedores} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd70cf30e81b54183cf91")?.activado,
    permisoEditarProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd738f30e81b54183cf92")?.activado,
    permisoDeshabilitarProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce49ef30e81b54183cfb3")?.activado,
  };
};

export default connect(mapStateToProps)(Proveedores);

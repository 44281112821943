import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Form as FormInitial,
  Row,
  Col,
  Select,
  Checkbox,
  Alert,
} from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  HeaderItem,
  BoxItem,
  InputItem,
  ButtonItem,
  NotificacionPolizas,
  round2Decimals,
  SwitchItem
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

const { Option } = Select;
moment.locale("es");

class VerFacturasProveedor extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    facturasProveedor: undefined,
    todasBancos: null,
    objetoBancos: null,
    todasMonedas: null,
    objetoMonedas: null,
    todasFormasDePago: null,
    objetoFormasDePago: null,
    monedaDePago: 'MXN',
    id: this.props.location.state.id,
    notasDeCreditoPendiente: [],
    objetoNotasPendientes: null
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "bancos/selectBancos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: res.data });
          this.setState({
            objetoBancos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "cajaefectivo/selectCajaEfectivo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: [...this.state.todasBancos, ...res.data] });
          this.setState({
            objetoBancos: [...this.state.objetoBancos ,...res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            })],
          });
        }
      });
      await axios
      .post(
        "formasdepago/selectFormasDePago",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasFormasDePago: res.data });
          this.setState({
            objetoFormasDePago: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cuentasporpagar/selectFacturasPProveedor",
        {
          empresaId: this.props.empresaId,
          proveedorId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del proveedor");
        } else {
          let nuevasFacturas = res.data.facturas.map((value) => {
            return {
              ...value,
              proyectoNombre: value.proyecto.nombre,
              tipoDeCambioCompra: value.tipoDeCambio,
              saldoPendiente: round2Decimals(value.totalPagar - value.totalPagado),
            };
          });
          this.setState({ facturasProveedor: nuevasFacturas, notasDeCreditoPendiente: res.data.notasDeCreditoPendiente,
            objetoNotasPendiente: res.data.notasDeCreditoPendiente.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {moment(value.fechaFactura).format('L') + " - " + dineroDisabledMask(value.totalPagar - value.totalPagado)+ ' - '+ value.moneda.abreviacion}
                </Option>
              );
            })
           });
          this.formRef.current.setFieldsValue({ facturas: nuevasFacturas });
        }
      });
      await axios
      .post("monedas/selectMonedas", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMonedas: res.data });
          this.setState({
            objetoMonedas: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (value.name[1] || value.name[1] === 0) {
        message.error(
          "Error: " + value.errors[0] + " en la fila " + (value.name[1] + 1)
        );
      } else {
        message.error("Error");
      }
      return null;
    });
  }
  onFinish(values) {
    let cantidadPagarTotal = 0;
    if(values.pagarConNotas){
      const notaCredito = this.state.notasDeCreditoPendiente.find((valueTodasNotas) => valueTodasNotas._id.toString() === values.notaId.toString())
      if(values.monto > notaCredito.totalPagar){
        return message.info('No se puede pagar mas del saldo pendiente de la nota de credito')
      }
    }
    values.facturas.map((value) => {
      let cantidadPagar = value.cantidadPagar ? value.cantidadPagar : 0;
      cantidadPagarTotal += cantidadPagar;
      return null;
    });
    if (cantidadPagarTotal === 0)
      return message.info(
        "Se neceitan cantidades para poder hacer esta operación"
      );
    this.setState({ loading: true });
    axios
      .post(
        "cuentasporpagar/pagarFacturasProveedor",
        {
          ...values,
          empresaId: this.props.empresaId,
          proveedorId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha recibido un pago");
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error(res.data.message);
        } else {
          message.error("Hubo un error y no se recibio el pago");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  onChangeBanco(bancoId){
    if(bancoId){
      const banco = this.state.todasBancos.find((valueTodosBancos) => valueTodosBancos._id.toString() === bancoId.toString())
      if(banco.caja){
        message.info('Se tendra que reponer el saldo en la caja chica')
      }
    }
  }

  onChangeNotaDeCredito(notaId) {
    if (notaId) {
      const notaCredito = this.state.notasDeCreditoPendiente.find((valueTodasNotas) => valueTodasNotas._id.toString() === notaId.toString())
      this.formRef.current.setFieldsValue({monedaId: notaCredito.monedaId, tipoDeCambioPago: notaCredito.tipoDeCambio})
    }
  }

  onChangeCheque(value){
    if(value.target.checked){
      this.formRef.current.setFieldsValue({formaDePagoId: '6125149afe07662acc100d59'})
    }else{
      this.formRef.current.setFieldsValue({formaDePagoId: '6125149afe07662acc100d5a'})
    }
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Cuentas por pagar "}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
                {this.state.notasDeCreditoPendiente.length > 0 &&
          <Alert onClick={() => this.formRef.current.setFieldsValue({pagarConNotas: true})} style={{ marginBottom: 10 }} description='Cambie a notas de credito para pagar con una de ellas' showIcon message="Este proveedor contiene notas de credito pendientes" type="warning" />
        }
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="facturasPendientesProveedor"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Pagar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
              <Col span={8}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                  <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const pagarConNotas = getFieldValue('pagarConNotas')
                        return (
                  <FormItem
                      required
                      margin
                      name="monedaId"
                      label="Moneda Pago"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                      initialValue={'60c7e4396465ad440c41c15a'}
                    >
                      <SelectItem onChange={(value) => {
                        const moneda = this.state?.todasMonedas.find((todasMonedas) => todasMonedas._id.toString() === value.toString())
                        if(value === '60c7e4396465ad440c41c15a'){
                          this.formRef.current.setFieldsValue({tipoDeCambioPago: 1})
                        }
                        if(moneda){
                          this.setState({monedaDePago: moneda.abreviacion})
                        }
                      }} placeholder="Moneda" disabled={pagarConNotas}>
                        {this.state.objetoMonedas}
                      </SelectItem>
                    </FormItem>
                        )
                    }}
                    </FormItem>
                    <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.pagarConNotas !== curr.pagarConNotas}>
                      {({getFieldValue}) => {

                        const monedaId = getFieldValue('monedaId')

                        
                        const pagarConNotas = getFieldValue('pagarConNotas')

                        const moneda = this.state.todasMonedas ? this.state.todasMonedas.find((valueTodasMonedas) => valueTodasMonedas._id.toString() === monedaId.toString()) : null

                        return(
                          <FormItem
                            required
                            number
                            margin
                            label={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            name="tipoDeCambioPago"
                            type="number"
                            initialValue={1}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            <InputNumberItem
                              disabled={monedaId === '60c7e4396465ad440c41c15a' || pagarConNotas}
                              dinero
                              placeholder={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                  </BoxItem>
                </Col>
                <Col span={16}>
                  <HeaderItem>  PROVEEDOR   :   { this.props.location.state.nombre} </HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Fecha"
                          name="fechaPago"
                          required
                          margin
                          initialValue={moment()}
                        >
                          <DatePickerItem
                            placeholder="Fecha de Pago"
                            allowClear={false}
                          />
                        </FormItem>
                        {this.state.notasDeCreditoPendiente.length > 0 &&
                      <FormItem
                        name="pagarConNotas"
                        label="Pagar C/Notas"
                        tooltip={{ title: "Pagar la factura con notas de credito" }}
                        margin
                        labelCol={{ span: 17 }}
                        wrapperCol={{ span: 7 }}
                        pattern
                        valuePropName="checked"
                      >
                        <SwitchItem placeholder="Pagar Con Notas" />
                      </FormItem>
                    }
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const pagarConNotas = getFieldValue('pagarConNotas')

                        if (pagarConNotas) {
                          return (
                            <FormItem
                              label="Nota"
                              name="notaId"
                              margin
                              required
                              labelCol={{ span: 7 }}
                              wrapperCol={{ span: 17 }}
                            >
                              <SelectItem placeholder="Nota" onChange={this.onChangeNotaDeCredito.bind(this)} >
                                {this.state.objetoNotasPendiente}
                              </SelectItem>
                            </FormItem>
                          )
                        } else {
                          return (
                            <>
                        <FormItem 
                        label="Banco" 
                        name="bancoId" 
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }} 
                        required
                        >
                          <SelectItem onChange={this.onChangeBanco.bind(this)} placeholder="Banco">
                            {this.state.objetoBancos}
                          </SelectItem>
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                                  {({getFieldValue}) => {
                                    const cheque = getFieldValue('cheque')
                                      return (
                                        <FormItem
                                        name="formaDePagoId"
                                        label="F Pago"
                                        initialValue="6125149afe07662acc100d5a"
                                        margin
                                        required
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 18 }} 
                                      >
                                        <SelectItem disabled={cheque} placeholder="Forma de Pago">
                                          {this.state.objetoFormasDePago}
                                        </SelectItem>
                                      </FormItem>
                                      )
                                  }}
                                </FormItem>
                        </>
                          )}
                      }}
                      </FormItem>
                      </Col>
                      <Col span={12}>
                      <Row>
                    <Col span={12}>
                      <FormItem
                        label="Cheque"
                        name="cheque"
                        margin
                        labelCol={{ span: 20 }}
                        wrapperCol={{ span: 4 }} 
                        valuePropName="checked"
                        initialValue={this.props.location.state.cheque}
                      >
                        <Checkbox onChange={this.onChangeCheque.bind(this)} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem shouldUpdate noStyle>
                        {({getFieldValue}) => {
                          const cheque = getFieldValue('cheque')
                          const bancoId = getFieldValue('bancoId')
                          if(cheque && bancoId){
                            let consecutivoCheque = this.state.todasBancos.find(tBancos => tBancos._id.toString() === bancoId.toString()).consecutivoCheque
                            if(!consecutivoCheque){
                              consecutivoCheque = 1
                            }
                            return (
                              <h1 style={{textAlign: "center", justifyContent: "center"}}>N° Cheque: {consecutivoCheque}</h1>
                            )
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                        <FormItem
                          name="referencia"
                          label="Referencia"
                          margin
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }} 
                          pattern
                        >
                          <InputItem   placeholder="Referencia" />
                        </FormItem>
                      </Col>
                    </Row>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>FACTURAS PENDIENTES</HeaderItem>
              {/* <BoxItem></BoxItem> */}
              <FormInitial.List name="facturas">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "10%" }}>
                              Codigo
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Proyecto
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Fecha Vencimiento
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Moneda
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              T. C.
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Total a Pagar
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Total Pagado
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Saldo Pendiente
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Cantidad a Pagar {this.state.monedaDePago}
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                        <tbody key={arrayKey}>
                          <tr>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.facturasProveedor[arrayKey]
                                    .codigoFactura
                                }
                              </p>
                            </td>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.facturasProveedor[arrayKey]
                                    .proyectoNombre
                                }
                              </p>
                            </td>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {moment(
                                  this.state.facturasProveedor[arrayKey]
                                    .fechaPago
                                )
                                  .locale("es")
                                  .format("L")}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.facturasProveedor[arrayKey]
                                    .moneda.abreviacion
                                }
                              </p>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "tipoDeCambioCompra"]}
                                fieldKey={[arrayKey, "tipoDeCambioCompra"]}
                                noStyle
                                numberCero
                                initialValue={1}
                                type='number'
                              >
                                <InputNumberItem 
                                  sinBorde
                                  disabled={this.state.facturasProveedor[arrayKey].monedaId === '60c7e4396465ad440c41c15a'} 
                                  style={{width: '100%'}} 
                                  placeholder={'Factura: '+this.state.facturasProveedor[arrayKey].tipoDeCambio} 
                                  dinero 
                                  size='small' 
                                />
                              </FormItem>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.facturasProveedor[arrayKey]
                                    .totalPagar
                                )}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.facturasProveedor[arrayKey]
                                    .totalPagado
                                )}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.facturasProveedor[arrayKey]
                                    .saldoPendiente
                                )}
                              </p>
                            </td>
                            <td className="td-border">
                              <ButtonItem
                                size="small"
                                type="primary"
                                style={{ width: "20%" }}
                                onClick={() => {
                                  let facturas =
                                    this.formRef.current.getFieldValue(
                                      "facturas"
                                    );

                                  const tipoDeCambioCompra = facturas[arrayKey].tipoDeCambioCompra
                                  const tipoDeCambioPago = this.formRef.current.getFieldValue('tipoDeCambioPago')
                                  
                                  const equivalencia = tipoDeCambioPago / tipoDeCambioCompra
  
                                  const saldoPendiente = round2Decimals(
                                    (this.state.facturasProveedor[arrayKey].saldoPendiente) / equivalencia
                                  );

                                  facturas[arrayKey].cantidadPagar = saldoPendiente;
                                  this.formRef.current.setFieldsValue({
                                    facturas: facturas,
                                  });
                                }}
                              >
                                Total
                              </ButtonItem>
                              <FormItem noStyle shouldUpdate>
                                {({getFieldValue}) => {

                                  const facturas = getFieldValue('facturas')

                                  const tipoDeCambioCompra = facturas[arrayKey].tipoDeCambioCompra
                                  const tipoDeCambioPago = getFieldValue('tipoDeCambioPago')

                                  const equivalencia = tipoDeCambioPago / tipoDeCambioCompra

                                  const saldoPendiente = round2Decimals(
                                    (this.state.facturasProveedor[arrayKey].saldoPendiente) / equivalencia
                                  );

                                  return (
                                    <FormItem
                                      {...restField}
                                      name={[name, "cantidadPagar"]}
                                      fieldKey={[arrayKey, "cantidadPagar"]}
                                      noStyle
                                      numberCero
                                      type="number"
                                      max={
                                        saldoPendiente
                                      }
                                    >
                                      <InputNumberItem
                                        style={{ width: "80%" }}
                                        placeholder={
                                          "Max: " + saldoPendiente
                                        }
                                        dinero
                                        size="small"
                                      />
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>

              <Row>
                <Col span={8}></Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      let facturas = getFieldValue("facturas");
                      if (!facturas) return null;
                      let total = 0;
                      facturas.map((value) => {
                        let cantidadPagar = value.cantidadPagar
                          ? value.cantidadPagar
                          : 0;
                        total += cantidadPagar;
                        return null;
                      });
                      return (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th
                                className="th-totales"
                                style={{ fontSize: 15 }}
                              >
                                Total a pagar
                              </th>
                              <td
                                className="td-totales-right"
                                style={{ fontSize: 15 }}
                              >
                                {dineroDisabledMask(total)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    }}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VerFacturasProveedor);

import React, { Component } from "react";
import { message, Row, Col, Spin, Select } from "antd";
import { Form, FormItem, SelectItem, ButtonItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";

const { Option } = Select

class LogisticaConfiguracion extends Component {
    formRef = React.createRef();
    state = {
        loading: false
    };
    componentDidMount() {
        this.consultarConfiguracion();
    }
    onFinish(values) {
        axios
            .post(
                "empresas/editarConfiguracionLogistica",
                { ...values, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Se ha modificado la configuracion de la empresa");
                    this.consultarConfiguracion();
                } else if (res.data.success === 1) {
                    message.error("No se pudo modificar la configuracion");
                } else {
                    message.error("Hubo un error y no se modifico la configuracion");
                }
            });
    }
    async consultarConfiguracion() {
        this.setState({ loading: true })
        await axios
            .post(
                "empresas/selectConfiguracionLogistica",
                { empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                    message.error("Error no se encontro configuracion de esta empresa");
                } else {
                    this.formRef.current.setFieldsValue({
                        ...res.data
                    });
                }
            });
        this.setState({ loading: false })
    }
    onFinishFailed(e) {
        e.errorFields.map((value, index) => {
            if (e.errorFields[index].name[1]) {
                message.error(
                    "Error: " +
                    e.errorFields[index].errors[0] +
                    " en la fila " +
                    (e.errorFields[index].name[1] + 1)
                );
            } else {
                message.error("Error: Falta un campo");
            }
            return null;
        });
    }
    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Form
                        name="logisticaConfiguracion"
                        layout
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                        ref={this.formRef}
                    >
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="Calculo Tiempo"
                                    margin
                                    name="calcularTiempoProveedor"
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <SelectItem
                                        placeholder="Calculo Tiempo"
                                    >
                                        <Option value={1} default>Todo</Option>
                                        <Option value={2}>Ordenes de compra</Option>
                                        <Option value={3}>Facturas Sin Orden de Compra</Option>
                                    </SelectItem>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="Calculo Max y Min"
                                    margin
                                    name="calcularMaxYMin"
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <SelectItem
                                        placeholder="Calculo Max y Min"
                                    >
                                        <Option value={1} default>Promedio</Option>
                                        <Option value={2}>Proveedor default</Option>
                                        <Option value={3}>Provedor mas rapido</Option>
                                        <Option value={4}>Provedor mas lento</Option>
                                    </SelectItem>
                                </FormItem>
                            </Col>
                        </Row>
                        <ButtonItem
                            type="primary"
                            htmlType="submit"
                            style={{ display: "block", margin: "0 0 0 auto" }}
                        >
                            Guardar Configuracion
                        </ButtonItem>
                    </Form>
                </Spin>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(LogisticaConfiguracion);

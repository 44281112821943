import { Col, Divider, PageHeader, Row, Spin, Form as FormInitial, message, Select,  } from 'antd'
import axios from 'axios';
import React, {Component} from 'react'
import { connect } from 'react-redux';
import {
    Form,
    FormItem,
    SelectItem,
    InputNumberItem,
    DatePickerItem,
    ButtonItem
  } from "../../../../Components/Items";
  import moment from "moment";
import "moment/locale/es";
import VistaPrevia from '../VistaPrevia/VistaPrevia';
import { FilePdfOutlined } from '@ant-design/icons';

  const {Option} = Select

class VerOrdenDeProduccion extends Component{
    formRef = React.createRef();
    state = {
      loading: false,
      objetoProyectos: null,
      todasProyectos: null,
      todasInsumos: null,
      objetoInsumos: null,
      modalVistaPrevia: false,
      modalVistaPreviaId: null
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "insumos/selectInsumosInventariables",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasInsumos: res.data });
            this.setState({
              objetoInsumos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "produccion/datosOrdenDeProduccion",
          {
            empresaId: this.props.empresaId,
            produccionId: this.props.location.state.id,
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.success !== 2) {
            message.error(
              "Error no se encontro informacion de la produccion"
            );
          } else {
            this.formRef.current.setFieldsValue({
                ...res.data,
                fechaProduccion: moment(res.data.fechaProduccion),
            });
          }
        });
      this.setState({ loading: false });
    }
    cerrarVistaPrevia() {
        this.setState({
          modalVistaPrevia: false,
          modalVistaPreviaId: null,
        });
      }
    atras() {
      this.props.history.goBack();
    }
    render(){
        return (
            <>
            <PageHeader
              onBack={this.atras.bind(this)}
              title="Ver Orden de Producción"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
            />
            <div style={{ backgroundColor: "white", padding: 20 }}>
              <Spin spinning={this.state.loading}>
                <Form
                  ref={this.formRef}
                  name="verOrdenDeProduccion"
                  layout
                  bottom={
                    <ButtonItem
                      type="primary"
                      htmlType="submit"
                      icon={<FilePdfOutlined/>}
                      onClick={() => {
                        this.setState({
                            modalVistaPrevia: true,
                            modalVistaPreviaId: this.props.location.state.id,
                          });
                      }}
                      style={{ display: "block", margin: "0 0 0 auto", backgroundColor: 'red', borderColor: 'red' }}
                    >
                      Ver Pdf
                    </ButtonItem>
                  }
                >
                <Divider
                    style={{ marginBottom: 10, marginTop: 5 }}
                    children="Insumo a Producir"
                  />
                  <Row>
                    <Col span={12}>
                    <FormItem label="Insumo a Generar" name="insumoGeneradoId" margin required>
                        <SelectItem disabled placeholder="Insumo a Generar">
                        {this.state.objetoInsumos}
                        </SelectItem>
                    </FormItem>
                    </Col>
                    <Col span={12}>
                    <FormItem
                        label="Cantidad a Generar"
                        name="cantidadGenerada"
                        number
                        required
                        type='number'
                        initialValue={1}
                        >
                        <InputNumberItem disabled />
                        </FormItem>
                    </Col>
                  </Row>
                  <Divider
                    style={{ marginBottom: 10, marginTop: 5 }}
                    children="Proyecto"
                  />
                  <Row>
                  <Col span={12}>
                    <FormItem label="Proyecto" name="proyectoId" margin required>
                        <SelectItem disabled placeholder="Proyecto">
                        {this.state.objetoProyectos}
                        </SelectItem>
                    </FormItem>
                      </Col>
                      <Col span={12}>
                      <FormItem
                        label="Fecha de Produccion"
                        name="fechaProduccion"
                        required
                        margin
                        initialValue={moment()}
                      >
                        <DatePickerItem
                        disabled
                          placeholder="Fecha de Produccion"
                          allowClear={false}
                        />
                      </FormItem>
                      </Col>
                  </Row>
                  <Divider
                    style={{ marginBottom: 10, marginTop: 5 }}
                    children="Insumos a Utilizar"
                  />
                     <FormInitial.List name="insumosUtilizados">
                      {(fields, { add, remove }) => (
                        <>
                          <table className="table">
                            {fields.length > 0 ? (
                              <thead>
                                <tr>
                                    <th className="th-border" style={{width: '40%'}}>Insumo</th>
                                    <th className="th-border" style={{width: '20%'}}>Cantidad</th>
                                    <th className="th-border" style={{width: '20%'}}>Costo Unitario</th>
                                    <th className="th-border" style={{width: '20%'}}>Costo</th>
                                  </tr>
                              </thead>
                            ) : null}
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                <tbody>
                                  <tr>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "insumoId"]}
                                        fieldKey={[arrayKey, "insumoId"]}
                                        required
                                        noStyle
                                      >
                                        <SelectItem
                                          sinBorde
                                         autoFocus
                                         disabled
                                          width="100%"
                                          placeholder="Insumo"
                                          onChange={(value) =>
                                              this.onChangeInsumo(
                                                value,
                                                arrayKey
                                              )
                                            }
                                        >
                                          {
                                            this.state
                                              .objetoInsumos
                                          }
                                        </SelectItem>
                                      </FormItem>
                                    </td>
                                    <td className="td-border">
                                          <FormItem
                                          {...restField}
                                          name={[name, "cantidad"]}
                                          fieldKey={[arrayKey, "cantidad"]}
                                          number
                                          noStyle
                                          required
                                          type='number'
                                          initialValue={undefined}
                                          >
                                          <InputNumberItem disabled sinBorde />
                                          </FormItem>
                                    </td>
                                    <td className="td-border">
                                          <FormItem
                                          {...restField}
                                          name={[name, "costo"]}
                                          fieldKey={[arrayKey, "costo"]}
                                          number
                                          noStyle
                                          required
                                          type='number'
                                          initialValue={undefined}
                                          >
                                          <InputNumberItem disabled dineroDisabled sinBorde />
                                          </FormItem>
                                    </td>
                                    <td className="td-border">
                                          <FormItem
                                          {...restField}
                                          name={[name, "costoTotal"]}
                                          fieldKey={[arrayKey, "costoTotal"]}
                                          number
                                          noStyle
                                          required
                                          type='number'
                                          initialValue={undefined}
                                          >
                                          <InputNumberItem disabled dineroDisabled sinBorde />
                                          </FormItem>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            )}
                          </table>
                        </>
                      )}
                    </FormInitial.List>
                    <Row>
                      <Col span={12} />
                      <Col span={6}>
                      <FormItem
                        label="Costo P/U"
                        name="costo"
                        number
                        type='number'
                        initialValue={1}
                        >
                        <InputNumberItem disabled dineroDisabled />
                        </FormItem>
                      </Col>
                      <Col span={6}>
                      <FormItem
                        label="Costo"
                        name="costoTotal"
                        number
                        type='number'
                        initialValue={1}
                        >
                        <InputNumberItem dineroDisabled disabled />
                        </FormItem>
                      </Col>
                    </Row>
                </Form>
              </Spin>
            </div>
            <VistaPrevia
              visible={this.state.modalVistaPrevia}
              id={this.state.modalVistaPreviaId}
              cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
            />
          </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
export default connect(mapStateToProps)(VerOrdenDeProduccion);
import {
  message,
  PageHeader,
  Spin,
  Select,
  Form as FormInitial,
  Divider,
  Row,
  Col,
  Input
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  cuentaMask,
  InputItem,
  ButtonItem,
  SwitchItem
} from "../../../../Components/Items";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import AgregarMarbetes from "../../Marbetes/AgregarMarbetes/AgregarMarbetes";

const { Option } = Select;

class AgregarInventario extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    todasInsumos: null,
    objetoInsumos: null,
    todasCuentaContable: null,
    objetoCuentaContable: null,
    modalAgregarCuentaContable: false,
    modalAgregarMarbetes: false,
    modalAgregarMarbetesIndex: null,
    modalAgregarMarbetesMarbetes: null
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "insumos/selectInsumosInventariables",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(value.nivel1 + value.nivel2 + value.nivel3 + value.nivel4 + value.nivel5) + ' - ' + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    if (!values.insumos || values.insumos?.length === 0)
      return message.error("Agregue insumos para agregar inventario");
    this.setState({ loading: true });
    axios
      .post(
        "agregarInventario/agregarOrdenAgregarInventario",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha registrado la orden"
          );
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error(res.data.message ? res.data.message : "No se puede registrar la orden");
        } else {
          message.error("Hubo un error y la orden no se registro");
        }
      });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo()
  }

  async onChangeInsumo(value, index) {
    if (this.formRef.current.getFieldValue('contabilizar')) {
      let arrayValue = await this.formRef.current.getFieldValue("insumos");
      const insumo = this.state.todasInsumos.find(
        (valueInsumos) => valueInsumos._id === arrayValue[index].insumoId
      );
      if (!insumo) return;

      arrayValue[index].costo = insumo.costo

      this.formRef.current.setFieldsValue({ insumos: arrayValue })
    }
  }

  abrirAgregarMarbetes(value, marbetes) {
    this.setState({
      modalAgregarMarbetes: true,
      modalAgregarMarbetesIndex: value,
      modalAgregarMarbetesMarbetes: marbetes
    })
  }

  cerrarAgregarMarbetes(value) {
    const insumos = this.formRef.current.getFieldValue('insumos')
    insumos[this.state.modalAgregarMarbetesIndex].marbetes = value
    this.formRef.current.setFieldsValue({
      insumos: insumos
    })
    this.setState({
      modalAgregarMarbetes: false,
      modalAgregarMarbetesIndex: null,
      modalAgregarMarbetesMarbetes: null
    })
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Orden - Agregar Inventario"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarInventario"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <FormItem
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.insumos?.length !== curr.insumos?.length
                }
              >
                {({ getFieldValue }) => {
                  const insumos = getFieldValue("insumos");
                  const disabled = insumos && insumos.length > 0 ? true : false;
                  return (
                    <>
                      <Row>
                        <Col span={12}>
                          <FormItem label="Proyecto" name="proyectoId" margin required>
                            <SelectItem disabled={disabled} placeholder="Proyecto">
                              {this.state.objetoProyectos}
                            </SelectItem>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            name="referencia"
                            label="Referencia"
                            margin
                            pattern
                          >
                            <InputItem placeholder="Referencia" />
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <FormItem
                            label="Contabilizar"
                            name="contabilizar"
                            margin
                            initialValue={true}
                            valuePropName="checked"
                          >
                            <SwitchItem disabled={disabled || !this.props.permisoContabilizarAgregarInventario} />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const contabilizar = getFieldValue('contabilizar')
                              if (contabilizar) {
                                return (
                                  <FormItem label="Cuenta Abono" margin>
                                    <Input.Group compact>
                                      <FormItem name="cuentaAbonoId" required noStyle>
                                        <SelectItem disabled={disabled} placeholder="Cuenta Abono" width="80%">
                                          {this.state.objetoCuentaContable}
                                        </SelectItem>
                                      </FormItem>
                                      <ButtonItem
                                        icon={<PlusOutlined />}
                                        style={{ width: "20%" }}
                                        disabled={disabled}
                                        onClick={this.abrirAgregarCuentaContable.bind(this)}
                                      />
                                    </Input.Group>
                                  </FormItem>
                                )
                              }
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                      <Divider
                        style={{ marginBottom: 10, marginTop: 5 }}
                        children="Insumos"
                      />
                      <FormInitial.List name="insumos">
                        {(fields, { add, remove }) => (
                          <>
                            <table className="table">
                              {fields.length > 0 ? (
                                <thead>
                                  <tr>
                                    <th className="th-border" style={{ width: '40%' }}>Insumo</th>
                                    <th className="th-border" style={{ width: '20%' }}>Costo</th>
                                    <th className="th-border" style={{ width: '20%' }}>Cantidad</th>
                                    <th className="th-border" style={{ width: '20%' }}>Accion</th>
                                  </tr>
                                </thead>
                              ) : null}
                              {fields.map(
                                ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                  <tbody>
                                    <tr>
                                      <td className="td-border">
                                        <FormItem
                                          {...restField}
                                          name={[name, "insumoId"]}
                                          fieldKey={[arrayKey, "insumoId"]}
                                          required
                                          noStyle
                                        >
                                          <SelectItem
                                            onChange={(value) => {
                                              this.onChangeInsumo(value, arrayKey)
                                            }}
                                            sinBorde
                                            autoFocus
                                            width="100%"
                                            placeholder="Insumo"
                                          >
                                            {
                                              this.state
                                                .objetoInsumos
                                            }
                                          </SelectItem>
                                        </FormItem>
                                      </td>
                                      <td className="td-border">
                                        <FormItem shouldUpdate noStyle>
                                          {({ getFieldValue }) => {

                                            const contabilizar = getFieldValue('contabilizar')

                                            return (
                                              <FormItem
                                                {...restField}
                                                name={[name, "costo"]}
                                                fieldKey={[arrayKey, "costo"]}
                                                numberCero
                                                noStyle
                                                required
                                                initialValue={0}
                                              >
                                                <InputNumberItem disabled={!contabilizar} sinBorde />
                                              </FormItem>
                                            )
                                          }}
                                        </FormItem>
                                      </td>
                                      <td className="td-border">
                                        <FormItem
                                          {...restField}
                                          name={[name, "cantidad"]}
                                          fieldKey={[arrayKey, "cantidad"]}
                                          number
                                          noStyle
                                          required
                                          initialValue={undefined}
                                        >
                                          <InputNumberItem sinBorde />
                                        </FormItem>
                                      </td>
                                      <td className="td-border">
                                        <Row>
                                          <Col span={18}>
                                            <FormItem
                                              noStyle
                                              shouldUpdate
                                            >
                                              {({ getFieldValue }) => {
                                                let arrayValue = getFieldValue("insumos");
                                                let mostrarMarbetes = false
                                                if (arrayValue[arrayKey]?.insumoId) {
                                                  const insumo = this.state.todasInsumos.find(
                                                    (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                                  );
                                                  mostrarMarbetes = insumo.marbetes
                                                }
                                                if (mostrarMarbetes) {
                                                  return (
                                                    <ButtonItem
                                                      type="primary"
                                                      size='small'
                                                      block
                                                      onClick={() => {
                                                        this.abrirAgregarMarbetes(arrayKey, arrayValue[arrayKey].marbetes)
                                                      }}
                                                    >
                                                      Marbetes
                                                    </ButtonItem>
                                                  )
                                                }
                                              }}
                                            </FormItem>
                                          </Col>
                                          <Col span={6} style={{ textAlign: 'center' }}>
                                            <MinusCircleOutlined
                                              onClick={() => remove(name)}
                                            />
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                            </table>
                            <FormItem
                              noStyle
                              shouldUpdate
                            >
                              {({ getFieldValue }) => {
                                const proyecto = getFieldValue("proyectoId");
                                const contabilizar = getFieldValue("contabilizar");
                                const cuentaAbonoId = getFieldValue("cuentaAbonoId");
                                return (
                                  <ButtonItem
                                    blanco
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    type="dashed"
                                    onClick={() => {
                                      if (contabilizar) {
                                        if (proyecto && cuentaAbonoId) {
                                          add()
                                        } else {
                                          message.info('Agregue los datos primero')
                                        }
                                      } else {
                                        if (proyecto) {
                                          add()
                                        } else {
                                          message.info('Agregue los datos primero')
                                        }
                                      }
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Agregar Campo
                                  </ButtonItem>
                                )
                              }}
                            </FormItem>
                          </>
                        )}
                      </FormInitial.List>
                    </>
                  )
                }}
              </FormItem>
            </Form>
          </Spin>
        </div>
        <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(this)}
        />
        <AgregarMarbetes
          visible={this.state.modalAgregarMarbetes}
          cerrarAgregarMarbetes={this.cerrarAgregarMarbetes.bind(this)}
          marbetes={this.state.modalAgregarMarbetesMarbetes}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoContabilizarAgregarInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6526099a3d4cd64267a901dd"
      )?.activado,
  };
};

export default connect(mapStateToProps)(AgregarInventario);

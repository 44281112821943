import { Form as FormInitial, Row, Col, Space, message } from 'antd';
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ModalItem,
  ButtonItem,
  FormItem,
  InputItem,
  Form
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ordenCompraPdf from "../../../../Pdf/ordenCompraPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MandarCorreoOrdenDeCompra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pdf: null,
    };
    this.formRef = React.createRef();

    this.cerrarMandarCorreoOrdenDeCompra = this.cerrarMandarCorreoOrdenDeCompra.bind(this);
    this.onFinish = this.onFinish.bind(this); 
    this.onFinishFailed = this.onFinishFailed.bind(this); 
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      console.log("Propiedad visible ha cambiado, valor actual:", this.props.visible);
      if (this.props.visible) {
        this.consultarTodo();
      }
    }
  }
  

  async consultarTodo() {
    this.setState({ loading: true });
    try {
      const res = await axios.post(
        "ordenesdecompra/datosPdf",
        { ordenId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      );

      const pdf = pdfMake.createPdf(ordenCompraPdf(res.data));
      this.setState(
        {
          loading: false,
          pdf: pdf,
        },
        () => {
          pdf.open({}, window.frames["OrdenCompraPdf"]);
        }
      );
    } catch (error) {
      console.error("Error al consultar los datos:", error);
      this.setState({ loading: false });
    }
  }

  cerrarMandarCorreoOrdenDeCompra() {
    this.props.cerrarMandarCorreoOrdenDeCompra();
  }

  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    if (values?.correosEnvio?.length > 0) {
      this.setState({ loading: true });
      axios
        .post(
          "ordenesdecompra/enviarOrden",
          { ...values, ordenId: this.props.id, empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Orden de compra enviado");
            this.formRef.current.resetFields();
            this.cerrarMandarCorreoOrdenDeCompra();
          } else if (res.data.success === 1) {
            message.error("No se pudo enviar la orden de compra a ese correo");
          } else {
            message.error("Sucedió un error y no se pudo enviar");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al enviar el correo");
          console.error("Error:", error);
        });
    } else {
      message.info('Proporcione al menos un correo de envío');
    }
  }
  

  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarMandarCorreoOrdenDeCompra} 
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="mandarCorreo"
          layout
          width = {700}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed} 
        >
          <FormInitial.List
            name="correosEnvio"
            initialValue={[{ correo: '' }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }, arrayKey) => (
                  <Row key={name}>
                    <Col span={22}>
                      <FormItem
                        name={[name, "correo"]}
                        fieldKey={[arrayKey, "correo"]}
                        label="Correo"
                        required
                        margin
                        email
                        pattern
                      >
                        <InputItem style={{ width: '100%' }} placeholder="Correo" />
                      </FormItem>
                    </Col>
                    <Col span={2}>
                      <div style={{ textAlign: 'center' }}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                {fields.length < 5 ? (
                  <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <ButtonItem
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Agregar
                    </ButtonItem>
                  </div>
                ) : null}
              </>
            )}
          </FormInitial.List>
          <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
            <Space>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Mandar Correo
              </ButtonItem>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarMandarCorreoOrdenDeCompra} // Removido el bind aquí ya que fue enlazado en el constructor
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cancelar
              </ButtonItem>
            </Space>
          </div>
        </Form>

        <iframe
          title={"Orden de Compra"}
          style={{ width: "100%", height: "100vh" }}
          id="OrdenCompraPdf"
          name="OrdenCompraPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(MandarCorreoOrdenDeCompra);

import React, { Component } from "react";
import {
  Layout,
  Menu,
  Spin,
  Card,
  Input,
  Button,
  message,
  notification,
  Row,
  Col
} from "antd";
import { NavLink } from "react-router-dom";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import logoHorizontal from "../../../assets/Biwo_Horizontal.png";
import logoGrande from "../../../assets/Biwo logo grande.png";
import infoRegistro from "../../../assets/Info-Registro.png";
import { connect } from "react-redux";
import { signup } from "../../../store/actions/user";
import { FormItem, Form, ButtonItem } from "../../../Components/Items";

const { Header, Content, Footer } = Layout;

class Registro extends Component {
  state = {
    paddingR: "5%",
    deviceWidth: 1000,
    loading: false,
  };

  onFinish(values) {
    this.setState({ loading: true });
    if (values.password === values.confirmarcontraseña) {
      this.props
        .signup(values)
        .then((data) => {
          this.setState({ loading: false });
          if (data?.success === 1) {
            message.info("Bienvenido " + data.nombre);
            this.props.history.push("/panelPrincipal");
          } else {
            message.warning(data.err);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          notification.error({
            message: "Error inesperado",
            description: err.toString(),
          });
        });
    } else {
      this.setState({ loading: false });
      message.error("Las contraseñas no coinciden");
    }
  }

  onFinishFailed() {
    message.error("Porfavor ingrese bien los datos");
  }

  render() {
    const center = {
      style: {
        margin: "auto",
        display: "block",
        alignSelf: "center",
      },
    };

    const current = this.props.history.location.pathname;
    return (
      <Layout>
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
          >
            <Menu.Item key={"/login"} id='menuitem-izquierda'>
              <NavLink to={"/login"}>
                <img src={logoHorizontal} alt='Logo' id="img-logo" />
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ marginTop: 64 }}>
          <Spin spinning={this.state.loading} size="large">
            <Row style={{padding: '1rem'}}>
              <Col xs={24} sm={12} lg={12} xl={15}>
                <img src={infoRegistro} id="img-center" alt="Biwo" style={{width: '100%', height: '100%'}} />
              </Col>
              <Col xs={24} sm={12} lg={12} xl={9} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Card id="register-card" bodyStyle={{ paddingTop: 0 }}>
                  <img src={logoGrande} id="img-center" alt="Biwo" />
                  <Form
                    defaultBehavior
                    name="login"
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed}
                  >
                    <FormItem>
                      <Input.Group compact>
                        <FormItem name="nombre" noStyle pattern required max={100}>
                          <Input
                            style={{ width: "33%" }}
                            placeholder="Nombre"
                            prefix={
                              <UserOutlined className="site-form-item-icon" />
                            }
                            maxLength={100}
                          />
                        </FormItem>
                        <FormItem
                          name="apellidoPaterno"
                          noStyle
                          pattern
                          required
                          maxLength={50}
                        >
                          <Input
                            style={{ width: "33%" }}
                            placeholder="Apellido P."
                            maxLength={50}
                          />
                        </FormItem>
                        <FormItem
                          name="apellidoMaterno"
                          noStyle
                          pattern
                          required
                          maxLength={50}
                        >
                          <Input
                            style={{ width: "33%" }}
                            placeholder="Apellido M."
                            maxLength={50}
                          />
                        </FormItem>
                      </Input.Group>
                    </FormItem>
                    <FormItem
                      name="nombreUsuario"
                      pattern
                      required
                      max={50}
                      min={5}
                    >
                      <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Nombre de usuario"
                        maxLength={50}
                      />
                    </FormItem>
                    <FormItem name="correo" pattern required max={100} email>
                      <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Correo"
                        maxLength={100}
                      />
                    </FormItem>
                    <FormItem name="password" pattern required max={50} min={5}>
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Contraseña"
                        maxLength={50}
                      />
                    </FormItem>
                    <FormItem
                      name="confirmarcontraseña"
                      pattern
                      required
                      max={50}
                      min={5}
                    >
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Confirmar Contraseña"
                        maxLength={50}
                      />
                    </FormItem>
                    <FormItem>
                      <ButtonItem type="primary" htmlType="submit" {...center}>
                        Registrarse
                      </ButtonItem>
                    </FormItem>
                    <FormItem>
                      <Button type="link" {...center}>
                        <NavLink to="/login">Regresar al LogIn</NavLink>
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Spin>
        </Content>
        <Footer id="content-background" style={{ textAlign: "center" }}>
          Biwo ©2020
        </Footer>
      </Layout>
    );
  }
}

export default connect(null, { signup })(Registro);

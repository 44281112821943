import { Spin, Row, Col } from "antd";
import React, { Component } from "react";
import { ResponsivePie } from "@nivo/pie";
import axios from "axios";
import { connect } from "react-redux";
import { dineroDisabledMask } from "../../../Components/Items";
import { ResponsiveBar } from "@nivo/bar";

/*function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}*/

class Inicio extends Component {
  state = {
    loading: false,
    ventasPorFamilia: [],
    comprasPorFamilia: [],
    flujoDeEfectivo: [],
    productosMasVendidos: [],
  };
  componentDidMount() {
    this.consultarVentasPorFamilia();
    this.consultarFlujoDeEfectivo();
    this.consultarProductosMasVendidos();
    this.consultarComprasPorFamilia();
  }
  async consultarVentasPorFamilia() {
    await axios
      .post(
        "inicio/ventasPorFamilia",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            ventasPorFamilia: res.data,
          });
        }
      });
  }
  async consultarComprasPorFamilia() {
    await axios
      .post(
        "inicio/comprasPorFamilia",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            comprasPorFamilia: res.data,
          });
        }
      });
  }
  async consultarFlujoDeEfectivo() {
    await axios
      .post(
        "inicio/flujoDeEfectivo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            flujoDeEfectivo: res.data,
          });
        }
      });
  }
  async consultarProductosMasVendidos() {
    await axios
      .post(
        "inicio/productosMasVendidos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            productosMasVendidos: res.data,
          });
        }
      });
  }
  render() {
    const colors = [
      "#5DBDD3",
      "#735DD3",
      "#D3735D",
      "#BDD35D",
      "#A6E528",
      "#28A6E5",
      "#6728E5",
      "#D0DC0C",
      "#0CDC16",
      "#160CDC",
    ];
    return (
      <>
        {/* <PageHeader
          title="Inicio"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        /> */}
        <div
          style={{
            backgroundColor: "white",
            padding: 20,
            paddingTop: 5,
            height: "90vh",
            display: "block",
          }}
        >
          <Spin spinning={this.state.loading}>
            <Row>
              <Col
                span={12}
                style={{ width: "100%", height: "40vh", padding: 20 }}
              >
                <h1>Ventas por Familia</h1>
                <ResponsivePie
                  data={this.state.ventasPorFamilia}
                  margin={{ top: 20, right: 80, bottom: 80, left: 80 }}
                  colors={colors}
                  sortByValue={true}
                  innerRadius={0.65}
                  padAngle={1}
                  id={(e) => e.label}
                  valueFormat={(e) => dineroDisabledMask(e)}
                  activeOuterRadiusOffset={8}
                  borderWidth={2}
                  borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                  enableArcLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: "color", modifiers: [] }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", "3"]],
                  }}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: "circle",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemTextColor: "#000",
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Col>
              <Col
                span={12}
                style={{ width: "100%", height: "40vh", padding: 20 }}
              >
                <h1>Flujo de efectivo</h1>
                <ResponsiveBar
                  data={this.state.flujoDeEfectivo}
                  keys={[
                    "Saldo Inicial",
                    "Movimentos del dia",
                    "Cuentas por Pagar",
                    "Cuentas por Cobrar",
                    "Saldo Final",
                  ]}
                  indexBy="dia"
                  valueFormat={(e) => dineroDisabledMask(Math.abs(e))}
                  margin={{bottom: 50, left: 60 }}
                  padding={0.3}
                  groupMode="grouped"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={{ scheme: "nivo" }}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 90,
                }}
                  axisTop={null}
                  axisRight={null}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                />
                {/* <ResponsiveLine
                  data={this.state.flujoDeEfectivo}
                  margin={{ top: 30, right: 30, bottom: 30, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  curve="basis"
                  yFormat={(e) => dineroDisabledMask(e)}
                  colors={shuffle([...colors])}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendOffset: -40,
                    legendPosition: "middle",
                  }}
                  lineWidth={3}
                  enablePoints={false}
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  enableArea={true}
                  useMesh={true}
                  legends={[]}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col
                span={12}
                style={{ width: "100%", height: "40vh", padding: 20 }}
              >
                <h1>Productos mas vendidos</h1>
                <ResponsiveBar
                  data={this.state.productosMasVendidos}
                  colors={colors}
                  keys={["dinero"]}
                  valueFormat={(e) => dineroDisabledMask(e)}
                  indexBy="label"
                  margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                  }}
                  enableLabel={false}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                />
              </Col>
              <Col
                span={12}
                style={{ width: "100%", height: "40vh", padding: 20 }}
              >
                <h1>Compras por Familia</h1>
                <ResponsivePie
                  data={this.state.comprasPorFamilia}
                  margin={{ top: 20, right: 80, bottom: 80, left: 80 }}
                  colors={colors}
                  sortByValue={true}
                  innerRadius={0.65}
                  padAngle={1}
                  id={(e) => e.label}
                  valueFormat={(e) => dineroDisabledMask(e)}
                  activeOuterRadiusOffset={8}
                  borderWidth={2}
                  borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                  enableArcLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: "color", modifiers: [] }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", "3"]],
                  }}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: "circle",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemTextColor: "#000",
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Inicio);

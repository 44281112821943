import {  Col, Divider, Form as FormInicial, message, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ButtonItem,
    Form,
    ModalItem,
    FormItem,
    InputItem,
    InputNumberItem,
} from "../../../../Components/Items";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

class AgregarMarbetes extends Component {
    formRef = React.createRef();
    formRefDesplejar = React.createRef();
    state = {
        loading: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            if(this.props.marbetes){
                this.formRef.current.setFieldsValue({marbetes: this.props.marbetes})
            }
        }

    }

    cerrarAgregarMarbetes() {
        const marbetes = this.formRef.current.getFieldValue('marbetes')
        this.props.cerrarAgregarMarbetes(marbetes);
        this.formRefDesplejar.current.resetFields()
        this.formRef.current.resetFields()
    }

    onFinish() {
        this.cerrarAgregarMarbetes()
    }

    onFinishFailed(e) {
        e.errorFields.map((value, index) => {
            if (e.errorFields[index].name[1]) {
                message.error(
                    "Error: " +
                    e.errorFields[index].errors[0] +
                    " en la fila " +
                    (e.errorFields[index].name[1] + 1)
                );
            } else {
                message.error("Error: Falta un campo");
            }
            return null;
        });
    }

    onFinishDesplejar(e) {
        if (e.inicio < e.final) {
            let arrayMarbetes = []
            for (let i = e.inicio; i <= e.final; i++) {
                arrayMarbetes.push({
                    numero: i,
                    cantidad: e.cantidad
                })
            }
            const marbetesActuales = this.formRef.current.getFieldValue('marbetes')
            if(marbetesActuales && marbetesActuales?.length > 0){
                this.formRef.current.setFieldsValue({ marbetes: [...marbetesActuales, ...arrayMarbetes] })
            }else{
                this.formRef.current.setFieldsValue({marbetes: arrayMarbetes})
            }
            this.formRefDesplejar.current.resetFields()
        } else {
            message.warning('El inicio tiene que ser menor que el final')
        }
    }

    onFinishFailedDesplejar() {
        message.error('Complete los datos para agregar los marbetes')
    }

    render() {
        return (
            <>
                <ModalItem
                    title="Agregar Marbetes"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarAgregarMarbetes.bind(this)}
                    paddingTop
                    width={700}
                >
                    <Form
                        ref={this.formRefDesplejar}
                        name="desplegarMarbetes"
                        layout
                        onFinish={this.onFinishDesplejar.bind(this)}
                        onFinishFailed={this.onFinishFailedDesplejar}
                    >
                        <Row>
                            <Col span={6}>
                                <FormItem
                                    label="Inicio"
                                    name="inicio"
                                    required
                                    margin
                                    number
                                >
                                    <InputNumberItem placeholder="Inicio" />
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Final"
                                    name="final"
                                    required
                                    margin
                                    number
                                >
                                    <InputNumberItem placeholder="Final" />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Cantidad"
                                    name="cantidad"
                                    required
                                    margin
                                    number
                                >
                                    <InputNumberItem placeholder="Cantidad" />
                                </FormItem>
                            </Col>
                            <Col span={4}>
                                <ButtonItem
                                    type="primary"
                                    htmlType="submit"
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Agregar
                                </ButtonItem>
                            </Col>
                        </Row>
                    </Form>
                    <Divider>Marbetes</Divider>
                    <Form
                        ref={this.formRef}
                        name="agregarMarbetes"
                        layout
                        bottom={
                            <ButtonItem
                                type="primary"
                                htmlType="submit"
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Agregar
                            </ButtonItem>
                        }
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <FormInicial.List name="marbetes">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                                        <Row key={arrayKey}>
                                            <Col span={7}>
                                                <FormItem
                                                    {...restField}
                                                    label="Numero"
                                                    name={[name, "numero"]}
                                                    fieldKey={[fieldKey, "numero"]}
                                                    required
                                                    margin
                                                >
                                                    <InputItem placeholder="Ej. 210158" />
                                                </FormItem>
                                            </Col>
                                            <Col span={7}>
                                                <FormItem
                                                    {...restField}
                                                    label="Numero2"
                                                    name={[name, "numero2"]}
                                                    fieldKey={[fieldKey, "numero2"]}
                                                    margin
                                                >
                                                    <InputItem placeholder="Ej. 210158" />
                                                </FormItem>
                                            </Col>
                                            <Col span={7}>
                                                <FormItem
                                                    {...restField}
                                                    label="Cantidad"
                                                    name={[name, "cantidad"]}
                                                    fieldKey={[fieldKey, "cantidad"]}
                                                    required
                                                    margin
                                                >
                                                    <InputNumberItem placeholder="Cantidad" />
                                                </FormItem>
                                            </Col>
                                            <Col span={3}>
                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col span={24}>
                                            <FormItem>
                                                <ButtonItem
                                                    type="dashed"
                                                    block
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Agregar Marbete
                                                </ButtonItem>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </FormInicial.List>
                    </Form>
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(AgregarMarbetes);

import { PageHeader, Space, Popconfirm, message } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { ButtonItem, TableItem, cuentaMask} from '../../../Components/Items'
import { connect } from "react-redux";
import AgregarFamilia from "./AgregarFamilia/AgregarFamilia";
import { EditOutlined, CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import EditarFamilia from "./EditarFamilia/EditarFamilia";


class Familias extends Component {
  state = {
    todasFamilia: [],
    modalAgregarFamilia: false,
    modalEditarFamilia: false,
    modalEditarFamiliaId: null,
    loading: false
  }
  componentDidMount() {
    this.consultarFamilias();
  }

  consultarFamilias() {
    this.setState({loading: true})
    axios
    .post(
      "familias/selectTodasFamilias",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasFamilia: res.data.map(value => {
            return {...value, accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }
  deshabilitarFamilia(value) {
    axios
      .post(
        "familias/deshabilitarFamilia",
        { familiaId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Familia deshabilitada");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarFamilias();
      });
  }
  habilitarFamilia(value) {
    axios
      .post(
        "familias/habilitarFamilia",
        { familiaId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Familia habilitada");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarFamilias();
      });
  }
  abrirAgregarFamilia() {
    this.setState({ modalAgregarFamilia: true });
  }
  cerrarAgregarFamilia() {
    this.setState({ modalAgregarFamilia: false });
    this.consultarFamilias();
  }
  abrirEditarFamilia(id) {
    this.setState({ modalEditarFamilia: true, modalEditarFamiliaId: id });
  }
  cerrarEditarFamilia() {
    this.setState({ modalEditarFamilia: false });
    this.consultarFamilias();
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Cuenta Inventario",
        dataIndex: "cuentaInventario",
        key: "cuentaInventario",
        render: (value) =>
          {
            if(value?.nivel1){
              return cuentaMask(
                value.nivel1 +
                  value.nivel2 +
                  value.nivel3 +
                  value.nivel4 +
                  value.nivel5
              )
            }else{
              return '-'
            }
          }
      },
      {
        title: "Cuenta Costo/Gasto",
        dataIndex: "cuentaCostoGasto",
        key: "cuentaCostoGasto",
        render: (value) =>
          {
            if(value?.nivel1){
              return cuentaMask(
                value.nivel1 +
                  value.nivel2 +
                  value.nivel3 +
                  value.nivel4 +
                  value.nivel5
              )
            }else{
              return '-'
            }
          }
      },
      {
        title: "Cuenta Ajuste Inventario",
        dataIndex: "cuentaAjusteInventario",
        key: "cuentaAjusteInventario",
        render: (value) =>
          {
            if(value?.nivel1){
              return cuentaMask(
                value.nivel1 +
                  value.nivel2 +
                  value.nivel3 +
                  value.nivel4 +
                  value.nivel5
              )
            }else{
              return '-'
            }
          }
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarFamilia &&
              <EditOutlined
                onClick={() => this.abrirEditarFamilia(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarFamilia &&
              <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar esta familia"
                  onConfirm={() => this.deshabilitarFamilia(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar esta familia"
                  onConfirm={() => this.habilitarFamilia(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
              </>
            }
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title="Familias"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarFamilia &&
            <ButtonItem
              type="primary"
              key="agregarFamilia"
              onClick={this.abrirAgregarFamilia.bind(this)}
            >
              Agregar Familia
            </ButtonItem>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasFamilia} columns={columns} />
        <AgregarFamilia
          visible={this.state.modalAgregarFamilia}
          cerrarAgregarFamilia={this.cerrarAgregarFamilia.bind(this)}
        />
                <EditarFamilia
          visible={this.state.modalEditarFamilia}
          id={this.state.modalEditarFamiliaId}
          cerrarEditarFamilia={this.cerrarEditarFamilia.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarFamilia: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ccf0bf30e81b54183cf79")?.activado,
    permisoEditarFamilia: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ccf3df30e81b54183cf7a")?.activado,
    permisoDeshabilitarFamilia: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ccfc7f30e81b54183cf7b")?.activado,
  };
};

export default connect(mapStateToProps)(Familias);
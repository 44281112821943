import React, { Component } from 'react';// importar react
import './Styles.css'; // importar carpeta de archivos css

class HeadersItemSmall extends Component {
    render() {
        return (
            <h1 className="headers-item-small">
                {this.props.children}
            </h1>
        );
    }
}

export default HeadersItemSmall;


/*import React, {Component} from 'react'

class HeadersItemSmall extends Component{
    render(){
        return(
            <h1 style={{
                // color: '#00D6F0',
                color: 'black',
                textAlign: 'center', 
                fontSize: 13,
                // backgroundColor: '#F6F6F6', 
                borderRadius: 1,
                marginTop: 6,
                margin: 3              
            } }>
            {this.props.children}</h1>
        )
    }
}

export default HeadersItemSmall*/
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  PageHeader,
  Row,
  Spin,
  Form as FormInitial,
  message,
  Select,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  InputItem,
  ButtonItem
} from "../../../../Components/Items";

const { Option } = Select;

class AgregarListaDeMateriales extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    todasInsumos: null,
    objetoInsumos: null,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "insumos/selectInsumosInventariables",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1] || e.errorFields[index].name[1] === 0) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "listasdemateriales/agregarListaDeMateriales",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se agrego una lista de materiales");
          this.atras();
        } else if (res.data.success === 1) {
          message.error("No se pudo guardar la lista de materiales");
        } else {
          message.error("Hubo un error y la lista no se guardo");
        }
      });
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Lista de Materiales"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarListaDeMateriales"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={12}>
                  <FormItem
                    label="Nombre"
                    name="nombre"
                    required
                    margin
                    pattern
                  >
                    <InputItem placeholder="Nombre" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Descripción"
                    name="descripcion"
                    margin
                    pattern
                  >
                    <InputItem placeholder="Descripción" />
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumo a Generar"
              />
              <Row>
                <Col span={12}>
                  <FormItem
                    label="Insumo a Generar"
                    name="insumoAGenerarId"
                    margin
                    required
                  >
                    <SelectItem placeholder="Insumo a Generar">
                      {this.state.objetoInsumos}
                    </SelectItem>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Cantidad a Generar"
                    name="cantidadAGenerar"
                    number
                    required
                    type="number"
                    initialValue={1}
                    tooltip="Esta deberia ser la cntidad minima que se puede producir."
                  >
                    <InputNumberItem />
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumos a Utilizar"
              />
              <FormInitial.List name="insumosAUtilizar">
                {(fields, { add, remove }) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "70%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "30%" }}>
                              Cantidad
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  fieldKey={[arrayKey, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    sinBorde
                                    autoFocus
                                    width="100%"
                                    placeholder="Insumo"
                                  >
                                    {this.state.objetoInsumos}
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidad"]}
                                  fieldKey={[arrayKey, "cantidad"]}
                                  number
                                  noStyle
                                  required
                                  type="number"
                                  initialValue={undefined}
                                >
                                  <InputNumberItem sinBorde />
                                </FormItem>
                              </td>
                              <td>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  style={{
                                    alignSelf: "center",
                                    justifySelf: "center",
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                    <FormItem noStyle>
                      <ButtonItem
                        style={{ marginTop: 10, marginBottom: 10 }}
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Agregar Campo
                      </ButtonItem>
                    </FormItem>
                  </>
                )}
              </FormInitial.List>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarListaDeMateriales);

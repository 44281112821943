import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonItem, TableItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";

moment.locale("es");

class Matrices extends Component {
  state = {
    todasMatrices: [],
    loading: false,
  };
  componentDidMount() {
    this.consultarMatrices();
  }
  consultarMatrices() {
    this.setState({ loading: true });
    axios
      .post(
        "matrices/selectInsumosYMatrices",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
            this.setState({
            todasMatrices: res.data.matrices.map(value => {
                return {...value, accion: { _id: value._id, estado: value.estado }}
              }),
          });
        } else  if (res.data.success === 1) {
            message.warning('No se encontraron matrices')
        } else{
            message.error('Hubo un error y no se encontraron los datos')
        }
      });
  }

  agregarMatriz() {
    this.props.history.push("/panel/agregarMatriz");
  }

  deshabilitarMatrices(value) {
    axios
      .post(
        "listasdemateriales/deshabilitarMatrices",
        { matrizId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Lista de Materiales deshabilitado");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarMatrices();
      });
  }
  habilitarMatrices(value) {
    axios
      .post(
        "listasdemateriales/habilitarMatrices",
        { matrizId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Lista de Materiales habilitado");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarMatrices();
      });
  }

  editarMatriz(id){
    this.props.history.push('/panel/editarMatriz', {id})
  }

  render() {
    const columns = [
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => {
              if (a.nombre < b.nombre) {
                return -1;
              }
              if (a.nombre > b.nombre) {
                return 1;
              }
              return 0;
            },
            buscar: "nombre",
          },
          {
            title: "Descripcion",
            dataIndex: "descripcion",
            key: "descripcion",
            sorter: (a, b) => {
              if (a.descripcion < b.descripcion) {
                return -1;
              }
              if (a.descripcion > b.descripcion) {
                return 1;
              }
              return 0;
            },
            buscar: "descripcion",
          },
      {
        title: "Insumo a Generar",
        dataIndex: "insumoAGenerar",
        key: "insumoAGenerar",
        sorter: (a, b) => {
          if (a.insumoAGenerar < b.insumoAGenerar) {
            return -1;
          }
          if (a.insumoAGenerar > b.insumoAGenerar) {
            return 1;
          }
          return 0;
        },
        buscar: "insumoAGenerar",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
              <EditOutlined
                onClick={() => this.editarMatriz(value._id)}
                style={{ color: "blue" }}
              />            
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar esta lista de materiales"
                    onConfirm={() => this.deshabilitarMatrices(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar esta lista de materiales"
                    onConfirm={() => this.habilitarMatrices(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
          </Space>
        ),
      },
    ];
    return (
      <>
        <PageHeader
          title="Matrices"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
              <ButtonItem
                type="primary"
                key="agregarMatriz"
                onClick={this.agregarMatriz.bind(this)}
              >
                Agregar Matriz
              </ButtonItem>
          }
        />
        <TableItem
            locale={{emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={(<>
                <p>Las matrices te permiten definir la lista de materia prima usada para utilizar en un presupuesto de precios unitarios.</p>
            </>)} />)}}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasMatrices}
          columns={columns}
          pagination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Matrices);
